/** @format */

import React from "react";
import "./GuestInfoTableRow.css";
import copy from "../../../../assets/Copy.svg";
import { Link } from "react-router-dom";

const GuestInfoTableRow = ({ index, names, uniqueNo, phone, id }) => {
  const [copySuccess, setCopySuccess] = React.useState("");
  const handleCopyClick = async () => {
    try {
      // Use the Clipboard API to copy text to the clipboard
      await navigator.clipboard.writeText(uniqueNo);
      setCopySuccess("Copied!");

      // Optionally, clear the success message after a short delay
      setTimeout(() => setCopySuccess(""), 2000);
    } catch (error) {
      console.error("Failed to copy text:", error);
      setCopySuccess("Failed to copy");
    }
  };
  return (
    <div className="GuestInfoTableRow" key={index}>
    
    
      <Link
        to={`/BarGuestDashboardRoom/${uniqueNo}`}
        onClick={() =>
          localStorage.setItem(
          "guestDashboardProps",
          JSON.stringify({ uniqueNo, id, names})
        )
        }
        className="GuestInfoTableRow-name-link"
      >
      
        <p className="GuestInfoTableRow-name">{names}</p>
      </Link>

      <div className="GuestInfoTableRow-id-sec">
        <p className="GuestInfoTableRow-unique-id">{uniqueNo}</p>
        <span style={{ display: "flex" }}>
          <button className="copy-btn" onClick={handleCopyClick}>
            <img src={copy} alt="" />
          </button>
          {copySuccess && (
            <span
              style={{ color: "#1f1515", marginLeft: "10px" }}
              className="copy-success"
            >
              {copySuccess}
            </span>
          )}
        </span>
      </div>

      <Link
        className="GuestInfoTableRow-mobile-number"
        to={`/BarGuestDashboardRoom/${uniqueNo}`}
        onClick={() =>
          localStorage.setItem(
          "guestDashboardProps",
          JSON.stringify({ uniqueNo, id, names})
        )
        }
      >
      
      
        <p className="GuestInfoTableRow-phone">{phone}</p>
      </Link>
    </div>
  );
};

export default GuestInfoTableRow;