/** @format */

import React, { useContext, useEffect, useState, useRef } from "react";
import toast from "react-hot-toast";
// import { Link, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Store } from "../../../Store";
import { NavLink } from "react-router-dom";
import top_icon from "../../../assets/top_arrow.svg";
import ropa_logo from "../../../assets/mob-logo.svg";
import receptionist2_pic from "../../../assets/receptionist_2.jpg";
import { convertToBase64 } from "../../../utils/convertImg";
import { CgSpinner } from 'react-icons/cg'; // Import the spinner icon from react-icons

import {
  useUpdateStaffMutation,
} from "../../../hooks/staff";


import "./ReceptionistDashboard.css";





const ReceptionistDashboard = ({
  setReceptionProfileShow,
  receptionProfileShow,
}) => {
  // const navigate = useNavigate();
  const reception = useRef(null);
  const { state: { userInfo }, dispatch} = useContext(Store);

  const [storedUserInfo, setStoredUserInfo] = useState(null);
  const { mutateAsync: updateProfile, refetch } = useUpdateStaffMutation();
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  // State to manage profile photo and whether it was updated
  const [isPhotoUpdated, setIsPhotoUpdated] = useState(false);

  // Handle outside click to close profile
  const closeReception = (e) => {
    if (e.target === reception.current) {
      setReceptionProfileShow(false);
    }
  };
  
  
  useEffect(() => {
    if (userInfo) {
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      const retrievedInfo = JSON.parse(localStorage.getItem("userInfo"));
      setStoredUserInfo(retrievedInfo);
      const storedPhoto = retrievedInfo?.photo
        if (storedPhoto) {
          // Update the state to show the new photo
          setIsPhotoUpdated(true);
        }
    }
  }, [userInfo, storedUserInfo]);
  
  
 
 
 

  // Signout handler
  const signoutHandler = async () => {
    try {
       // Dispatch signout action
       dispatch({ type: "USER_SIGNOUT" });

       // Remove any client-side session data
       localStorage.removeItem("userInfo");

       // Clear the token cookie using a server-side request
       await fetch(`${apiUrl}/api/deauth/logout`, {
         method: "POST",
         credentials: "include", // Send cookies with the request
       });

       // Optionally clear cookies from client-side (fallback)
       document.cookie = "token=; path=/; domain=" + window.location.hostname + "; max-age=0; secure; SameSite=None;";

       // Redirect to sign-in page
       window.location.href = "/";

       // Optionally show a success message
       toast.success("Signed out successfully.");
     } catch (error) {
       console.error("Error during signout:", error);
       toast.error("Failed to sign out. Please try again.");
     }
    };
    
    
    
    
    
    
    
    
    

  // Upload photo handler
  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const base64 = await convertToBase64(file);
        
        localStorage.setItem('photo', base64);
       
        setIsPhotoUpdated(true);
      } catch (error) {
        console.error("Error converting image:", error);
        toast.error("Failed to process the image.");
      }
    }
  };
   
   
   // When you start working on this, after update, localstorage should be refreshed to have new photo
  // Submit handler for updating the profile
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!isPhotoUpdated) {
      toast.error("No changes to the photo");
      return;
    }

    try {
      const updatedData = storedUserInfo?.photo || receptionist2_pic;

      const response = await updateProfile({ id: userInfo._id, updatedData });

      if (response) {
        toast.success("Profile photo updated successfully");
        refetch();
        setIsPhotoUpdated(false);
      } else {
        toast.error("Failed to update profile photo.");
      }
    } catch (error) {}
  };

  return (
  
  

    <div
      className={`ReceptionistDashboard ${receptionProfileShow ? " " : "hide"}`}
    >
    
    
      <div className="ReceptionistDashboard-arrow-up">
        <img src={top_icon} alt="" ref={reception} onClick={closeReception} />
      </div>
      
      
      
      <div className="ReceptionistDashboard-upper-sec">
        <div className="ReceptionistDashboard-logo">
          <Link to="/">
            <img src={ropa_logo} alt="" />
          </Link>
        </div>
        <div className="ReceptionistDashboard-profile">
        
        
        
          <div className="rdb-profile">
          
          
          
            <label htmlFor="upload-photo">
              {isPhotoUpdated ? (
            <div className="image-container">
              <img
                src={storedUserInfo ? storedUserInfo.photo : receptionist2_pic}
                alt="Staff Profile"
              />
            </div>
          ) : (
            // Display the rotating CgSpinner on top of the image circle
            <div className="image-container">
              <CgSpinner size={30} className="loading-spinner rotate" />
            </div>
          )}
            </label>
            
          </div>
          
          
          
          
          <div className="text-container">
            <h2 className="fullname-text">{storedUserInfo ? storedUserInfo.fullName : ""}</h2>
            
            
            <form className="upload-form" onSubmit={handleSubmit}>
              <input
                type="file"
                onChange={uploadPhoto}
                id="upload-photo"
                style={{ display: "none" }}
                accept="image/*"
              />
              {isPhotoUpdated && (
                <button
                  type="submit"
                  className="update-btn"
                  style={{ color: "green", padding: "8px" }}
                >
                  Update Photo
                </button>
              )}
            </form>
            
            
          </div>
          
          
       
          
          
          
          
          
          
        </div>
      </div>
      <div className="ReceptionistDashboard-lower-sec">
        <ul>
          <NavLink
            to="/GuestAccessPage"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Home</li>
          </NavLink>



          <NavLink
            to="/GuestAccount"
            className={({ isActive }) => {
              return isActive ? "hover-rdb-btn" : "";
            }}
          >
            <li>Payments</li>
          </NavLink>
          <li>
            <button
              className="logout-btn ReceptionistDashboard-login"
              onClick={signoutHandler}
            >
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
    


  );
};

export default ReceptionistDashboard;
