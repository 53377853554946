/** @format */

import "./App.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { Toaster } from "react-hot-toast";
import { Route, HashRouter as Router, Routes } from "react-router-dom"; // Import BrowserRouter

import CashierAccount from "./Components/Staff/Cashier/CashierAccount/CashierAccount";
import CashierDashboard from "./Components/Staff/Cashier/CashierDashboard/CashierDashboard";

import CashierIncoming from "./Components/Staff/Cashier/CashierIncoming/CashierIncoming";


import CashierExpenses from "./Components/Staff/Cashier/CashierExpenses/CashierExpenses";

import ReceptionistExpenses from "./Components/Staff/Staff-Receptionist/ReceptionistExpenses/ReceptionistExpenses";

import BartenderExpenses from "./Components/Staff/Staff-BartenderAndServers/BartenderExpenses/BartenderExpenses";


import CashierGuestDashboardRooms from "./Components/Staff/Cashier/CashierGuestDashboardRooms/CashierGuestDashboardRooms";
import CashierManageGuest from "./Components/Staff/Cashier/CashierManageGuest/CashierManageGuest";

import DashboardComponents from "./Components/DashboardComponents/DashboardComponents";

import DashboardLaundry from "./Components/Staff/Laundry/DashboardLaundry/DashboardLaundry";


import DashboardRoom from "./Components/DashboardRoom/DashboardRoom";

import GeneralAccount from "./Components/GeneralAccount/GeneralAccount";

import Booking from "./Components/Guest/Booking/Booking";
import GuestAccessPage from "./Components/Guest/GuestAccessPage/GuestAccessPage";
import GuestAccount from "./Components/Guest/GuestAccount/GuestAccount";
import GuestDashboardAccount from "./Components/GuestDashboardAccount/GuestDashboardAccount";
import GuestRoom from "./Components/GuestRoom/GuestRoom";
import LandingPage from "./Components/LandingPage/LandingPage";
import AdminAccounts from "./Components/Master-Admin/AdminAccounts/AdminAccounts";
import AdminDashboard from "./Components/Master-Admin/AdminDashboard/AdminDashboard";
import AdminLogin from "./Components/Master-Admin/AdminLogin/AdminLogin";
import CreateStaff from "./Components/Master-Admin/CreateStaff/CreateStaff";
import StaffInformation from "./Components/Master-Admin/StaffInformation/StaffInformation";
import StaffList from "./Components/Master-Admin/StaffList/StaffList";
import Receipt from "./Components/Receipt/Receipt";
import ReceptionistGuestPayment from "./Components/ReceptionistGuestPayment/ReceptionistGuestPayment";
import Register from "./Components/Register/Register";


/*import Reservations from "./Components/Reservations/Reservations";
*/


import BartenderAccount from "./Components/Staff/Staff-BartenderAndServers/BartenderAccount/BartenderAccount";
import BartenderDashboard from "./Components/Staff/Staff-BartenderAndServers/BartenderDashboard/BartenderDashboard";
import BartenderManageGuest from "./Components/Staff/Staff-BartenderAndServers/BartenderManageGuest/BartenderManageGuest";
import BillTo from "./Components/Staff/Staff-BartenderAndServers/BillTo/BillTo";
import OpenSales from "./Components/Staff/Staff-BartenderAndServers/OpenSales/OpenSales";
import AssignRoom from "./Components/Staff/Staff-Receptionist/AssignRoom/AssignRoom";


import GuestDashBoardRoom from "./Components/Staff/Staff-Receptionist/GuestDashBoardRoom/GuestDashBoardRoom";



import Reservations from "./Components/Staff/Staff-Receptionist/Reservations/Reservations";


import BarGuestDashBoardRoom from "./Components/Staff/Staff-BartenderAndServers/BarGuestDashBoardRoom/BarGuestDashBoardRoom";



import ManageGuest from "./Components/Staff/Staff-Receptionist/ManageGuest/ManageGuest";

//import ManagerDashboard from "./Components/Staff/Manager/ManagerDashboard/ManagerDashboard";
//import RoomManagement from "./Components/Staff/Manager/RoomManagement/RoomManagement";



import ReceptionistDashboard from "./Components/Staff/Staff-Receptionist/ReceptionistDashboard/ReceptionistDashboard";

import StaffDashboard from "./Components/Staff/StaffDashboard/StaffDashboard";







import OnlineBookingList from "./Components/Staff/Staff-Receptionist/OnlineBookingList/OnlineBookingList";
import ReceptionAccount from "./Components/Staff/Staff-Receptionist/ReceptionAccount/ReceptionAccount";

import BarRegisterGuests from "./Components/Staff/Staff-BartenderAndServers/BarRegisterGuests/BarRegisterGuests";

import RegisterGuests from "./Components/Staff/Staff-Receptionist/RegisterGuests/RegisterGuests";


import ReservationInfo from "./Components/Staff/Staff-Receptionist/ReservationInfo/ReservationInfo";
import SignIn from "./Components/Staff/Staff-SignIn/SignIn";

import UpdateGuestPayment from "./Components/Staff/Staff-Receptionist/UpdateGuestPayment/UpdateGuestPayment";

import BarUpdateGuestPayment from "./Components/Staff/Staff-BartenderAndServers/BarUpdateGuestPayment/BarUpdateGuestPayment";


import { StoreProvider } from "./Store";

import Expenses from "./Components/Master-Admin/AdminAccounts/Expenses";

import AdminManageGuest from "./Components/Master-Admin/AdminManageGuest/AdminManageGuest";


import CashierAllExpenses from "./Components/Staff/Cashier/CashierAllExpenses/CashierAllExpenses";

import CExpenses from "./Components/Master-Admin/AdminAccounts/CExpenses";

function App() {
  const [queryClient] = React.useState(() => new QueryClient());

  return (
    <Router>
      {" "}
      {/* Wrap your entire application inside Router */}
      <div className="App">
        <div>
          <Toaster
            position="top-right"
            reverseOrder={false}
            gutter={8}
            containerClassName=""
            containerStyle={{}}
            toastOptions={{
              // Define default options
              className: "",
              duration: 5000,
              style: {
                background: "#363636",
                color: "#fff",
              },
            }}
          />
          <StoreProvider>
            <QueryClientProvider client={queryClient}>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/Booking" element={<Booking />} />{" "}
                {/* Define your Booking route */}
                <Route
                  path="/DashboardComponents"
                  element={<DashboardComponents />}
                />
                <Route path="/DashboardRoom" element={<DashboardRoom />} />
                <Route path="/Register" element={<Register />} />
                <Route
                  path="/DashboardLaundry"
                  element={<DashboardLaundry />}
                />
                <Route path="/GuestAccount" element={<GuestAccount />} />
                <Route path="/SignIn" element={<SignIn />} />
                <Route
                  path="/GuestDashboardAccount"
                  element={<GuestDashboardAccount />}
                />

                <Route path="/AssignRoom/:id" element={<AssignRoom />} />
                
                <Route path="/RegisterGuests" element={<RegisterGuests />} />
                <Route path="/BarRegisterGuests" element={<BarRegisterGuests />} />
                
                
                {/*<Route path="/Reservations" element={<Reservations />} />*/}
                
                
               <Route path="/Reservations" element={<Reservations />} />
                
               <Route path="/CashierIncoming" element={<CashierIncoming />} />
                
                
               <Route path="/GuestRoom" element={<GuestRoom />} />
                
                <Route
                  path="/GuestDashboardRoom/:uniqueNo"
                  element={<GuestDashBoardRoom />}
                />
                
                <Route
                  path="/BarGuestDashboardRoom/:uniqueNo"
                  element={<BarGuestDashBoardRoom />}
                />
                
                <Route path="/GeneralAccount" element={<GeneralAccount />} />
                
                
                {/*
                <Route path="/ManagerDashboard" element={<ManagerDashboard />} />
                <Route path="/RoomManagement" element={<RoomManagement />} />
                */}
                
                
                <Route
                  path="/GuestAccessPage"
                  element={<GuestAccessPage />}
                />{" "}
                {/* Define your GuestAccessPage route */}
                <Route
                  path="/ReceptionistGuestPayment"
                  element={<ReceptionistGuestPayment />}
                />

                
               <Route
                  path="/AdminManageGuest"
                  element={<AdminManageGuest />}
                />        
                
                
                <Route
                  path="/ReceptionistDashboard"
                  element={<ReceptionistDashboard />}
                />
                <Route
                  path="/StaffDashboard"
                  element={<StaffDashboard />}
                />

                
                
                <Route
                  path="/UpdateGuestPayment/:identity"
                  element={<UpdateGuestPayment />}
                />
                
                <Route
                  path="/BarUpdateGuestPayment/:identity"
                  element={<BarUpdateGuestPayment />}
                />
                
                <Route path="/ReservationInfo/:guest" element={<ReservationInfo />} />

                <Route path="/ManageGuest" element={<ManageGuest />} />
                <Route
                  path="/ReceptionAccount"
                  element={<ReceptionAccount />}
                />
                <Route
                  path="/OnlineBookingList"
                  element={<OnlineBookingList />}
                />
                <Route path="/CreateStaff" element={<CreateStaff />} />
                <Route path="/AdminLogin" element={<AdminLogin />} />
                <Route path="/AdminDashboard" element={<AdminDashboard />} />
                <Route path="/StaffList" element={<StaffList />} />
                <Route

                  path="/StaffInformation/:id"

                  element={<StaffInformation />}
                />
                <Route path="/AdminAccounts" element={<AdminAccounts />} />
                
                <Route path="/Expenses" element={<Expenses />} />
                <Route path="/CashierAllExpenses" element={<CashierAllExpenses />} />
                
                <Route path="/CExpenses" element={<CExpenses />} />
                <Route path="/OpenSales" element={<OpenSales />} />

                <Route path="/Receipt/:identity" element={<Receipt />} />

                <Route
                  path="/BartenderDashboard"
                  element={<BartenderDashboard />}
                />
                <Route path="/BillTo" element={<BillTo />} />
                <Route
                  path="/BartenderAccount"
                  element={<BartenderAccount />}
                />
                <Route
                  path="/BartenderManageGuest"
                  element={<BartenderManageGuest />}
                />
                <Route
                  path="/CashierDashboard"
                  element={<CashierDashboard />}
                />
                <Route path="/CashierAccount" element={<CashierAccount />} />
                <Route
                  path="/CashierManageGuest"
                  element={<CashierManageGuest />}
                />
                <Route
                  path="/CashierGuestDashboardRooms"
                  element={<CashierGuestDashboardRooms />}
                />
                
                <Route path="/CashierExpenses" element={<CashierExpenses />} />
                <Route path="/ReceptionistExpenses" element={<ReceptionistExpenses />} />
                <Route path="/BartenderExpenses" element={<BartenderExpenses />} />
                
                
                
                
              </Routes>
            </QueryClientProvider>
          </StoreProvider>
        </div>
      </div>
    </Router>
  );
}

export default App;
