import React, { useState } from "react";
import "./CashierGuestDashboardRooms.css";
import play_icon from "../../../../assets/play-icon.svg";
import CashierGuestDashboardRoomsList from "./CashierGuestDashboardRoomsList/CashierGuestDashboardRoomsList";


import ReceptionistDashboard from "../CashierProfile/ReceptionistDashboard";
import ReceptionHeader from "../CashierHeader/ReceptionHeader";


import { Link } from "react-router-dom";

const CashierGuestDashboardRoomsItems = [
  {
    name: "Ropajo Delux",
    date: "13.02.2023",
    CheckIn: "06:47pm",
    Amount: "30,000.00",
  },
  {
    name: "Ropajo Delux",
    date: "13.02.2023",
    CheckIn: "06:47pm",
    Amount: "30,000.00",
  },
  {
    name: "Ropajo Delux",
    date: "13.02.2023",
    CheckIn: "06:47pm",
    Amount: "30,000.00",
  },
];

const CashierGuestDashboardRooms = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  
  
  const [roomVisibility, setRoomVisibility] = useState(false);
  const [laundryVisibility, setLaundryVisibility] = useState(false);
  const [restaurantVisibility, setRestaurantVisibility] = useState(false);
  const [barVisibility, setBarVisibility] = useState(false);
  const [othersVisibility, setOthersVisibility] = useState(false);
  
  
  
  return (
    <div className="CashierGuestDashboardRooms">
    
    
      <div className="CashierGuestDashboardRooms-profile">
        <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      
      <div className="CashierGuestDashboardRooms-space">
      <div className="CashierGuestDashboardRooms-mobile-header">
          <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
        </div>
        
        
        <div className="CashierGuestDashboardRooms-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="CashierGuestDashboardRooms-space-body">
          <div className="CashierGuestDashboardRooms-title-board">
            <div className="CashierGuestDashboardRooms-user-detail">
              <p>Peter Patrick F.</p> <p>R1410x</p>
            </div>
            <div className="CashierGuestDashboardRooms-button-select">
              <button>Payments</button>
              <button><Link to='/Receipt' >Receipt</Link></button>
              <button>Checkout</button>
            </div>
          </div>
          <section className="CashierGuestDashboardRooms-board">
            <section className="CashierGuestDashboardRooms-dropdown-holder">
              <div className="CashierGuestDashboardRooms-drop-down">
                <div className="CashierGuestDashboardRooms-drop-down-btn">
                  <div className="CashierGuestDashboardRooms-left-head-data">
                    <img
                      src={play_icon}
                      alt=""
                      onClick={() => setRoomVisibility(!roomVisibility)}
                      className={`${
                        roomVisibility ? "clicked-class" : "clicked-back"
                      }`}
                    />{" "}
                    <span>Room:</span>
                  </div>
                </div>
                {roomVisibility && (
                  <div className="CashierGuestDashboardRooms-drop-down-body">
                    <section className="CashierGuestDashboardRooms-table">
                      <div className="CashierGuestDashboardRooms-table-head">
                        <p>Name</p>
                        <p>Date</p>
                        <p>Check in</p>
                        <p>Amount</p>
                      </div>
                      <div className="CashierGuestDashboardRooms-table-body">
                        {CashierGuestDashboardRoomsItems.map((items, index) => (
                          <CashierGuestDashboardRoomsList
                            index={index}
                            Name={items.name}
                            date={items.date}
                            CheckIn={items.CheckIn}
                            Amount={items.Amount}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                )}
                ;
              </div>
              <div className="CashierGuestDashboardRooms-drop-down">
                <div className="CashierGuestDashboardRooms-drop-down-btn">
                  <div className="CashierGuestDashboardRooms-left-head-data">
                    <img
                      src={play_icon}
                      alt=""
                      onClick={() => setLaundryVisibility(!laundryVisibility)}
                      className={`${
                        laundryVisibility ? "clicked-class" : "clicked-back"
                      }`}
                    />{" "}
                    <span>Laundry:</span>
                  </div>
                </div>
                {laundryVisibility && (
                  <div className="CashierGuestDashboardRooms-drop-down-body">
                    <section className="CashierGuestDashboardRooms-table">
                      <div className="CashierGuestDashboardRooms-table-head">
                        <p>Name</p>
                        <p>Date</p>
                        <p>Check in</p>
                        <p>Amount</p>
                      </div>
                      <div className="CashierGuestDashboardRooms-table-body">
                        {CashierGuestDashboardRoomsItems.map((items, index) => (
                          <CashierGuestDashboardRoomsList
                            index={index}
                            Name={items.name}
                            date={items.date}
                            CheckIn={items.CheckIn}
                            Amount={items.Amount}
                          />
                        ))}
                      </div>
                    </section>
                  </div>
                )}
                ;
              </div>
              <div className="CashierGuestDashboardRooms-drop-down">
                <div className="CashierGuestDashboardRooms-drop-down-btn">
                  <div className="CashierGuestDashboardRooms-left-head-data">
                    <img
                      src={play_icon}
                      alt=""
                      onClick={() =>
                        setRestaurantVisibility(!restaurantVisibility)
                      }
                      className={`${
                        restaurantVisibility ? "clicked-class" : "clicked-back"
                      }`}
                    />{" "}
                    <span>Restaurant:</span>
                  </div>
                </div>
                {restaurantVisibility && (
                  <div className="CashierGuestDashboardRooms-drop-down-body">
                    <section className="CashierGuestDashboardRooms-table">
                      <div className="CashierGuestDashboardRooms-table-head">
                        <p>Name</p>
                        <p>Date</p>
                        <p>Check in</p>
                        <p>Amount</p>
                      </div>
                      <div className="CashierGuestDashboardRooms-table-body">
                      {
                          CashierGuestDashboardRoomsItems.map((items, index) => (
                            <CashierGuestDashboardRoomsList
                            index = {index}
                            Name = {items.name}
                            date = {items.date}
                            CheckIn = {items.CheckIn}
                            Amount = {items.Amount}
                            />
                          ))
                          }
                      </div>
                    </section>
                  </div>
                )}
                ;
              </div>
              <div className="CashierGuestDashboardRooms-drop-down">
                <div className="CashierGuestDashboardRooms-drop-down-btn">
                  <div className="CashierGuestDashboardRooms-left-head-data">
                    <img
                      src={play_icon}
                      alt=""
                      onClick={() => setBarVisibility(!barVisibility)}
                      className={`${
                        barVisibility ? "clicked-class" : "clicked-back"
                      }`}
                    />{" "}
                    <span>Pool:</span>
                  </div>
                </div>
                {barVisibility && (
                  <div className="CashierGuestDashboardRooms-drop-down-body">
                    <section className="CashierGuestDashboardRooms-table">
                      <div className="CashierGuestDashboardRooms-table-head">
                        <p>Name</p>
                        <p>Date</p>
                        <p>Check in</p>
                        <p>Amount</p>
                      </div>
                      <div className="CashierGuestDashboardRooms-table-body">
                      {
                          CashierGuestDashboardRoomsItems.map((items, index) => (
                            <CashierGuestDashboardRoomsList
                            index = {index}
                            Name = {items.name}
                            date = {items.date}
                            CheckIn = {items.CheckIn}
                            Amount = {items.Amount}
                            />
                          ))
                          }
                      </div>
                    </section>
                  </div>
                )}
                ;
              </div>
              <div className="CashierGuestDashboardRooms-drop-down">
                <div className="CashierGuestDashboardRooms-drop-down-btn">
                  <div className="CashierGuestDashboardRooms-left-head-data">
                    <img
                      src={play_icon}
                      alt=""
                      onClick={() => setOthersVisibility(!othersVisibility)}
                      className={`${
                        othersVisibility ? "clicked-class" : "clicked-back"
                      }`}
                    />{" "}
                    <span>Others:</span>
                  </div>
                </div>
                {othersVisibility && (
                  <div className="CashierGuestDashboardRooms-drop-down-body">
                    <section className="CashierGuestDashboardRooms-table">
                      <div className="CashierGuestDashboardRooms-table-head">
                        <p>Name</p>
                        <p>Date</p>
                        <p>Check in</p>
                        <p>Amount</p>
                      </div>
                      <div className="CashierGuestDashboardRooms-table-body">
                      {
                          CashierGuestDashboardRoomsItems.map((items, index) => (
                            <CashierGuestDashboardRoomsList
                            index = {index}
                            Name = {items.name}
                            date = {items.date}
                            CheckIn = {items.CheckIn}
                            Amount = {items.Amount}
                            />
                          ))
                          }
                      </div>
                    </section>
                  </div>
                )}
                ;
              </div>
            </section>
            <section className="CashierGuestDashboardRooms-foot">
              <div className="CashierGuestDashboardRooms-foot-wrapper">
                <div className="CashierGuestDashboardRooms-foot-left">
                  <h2>TOTAL</h2>
                </div>
                <div className="CashierGuestDashboardRooms-foot-right">
                  <div>
                    <p>Bill:</p>
                    <p className="CashierGuestDashboardRooms-red">216,500.00</p>
                  </div>
                  <div>
                    <p>Paid:</p>
                    <p className="CashierGuestDashboardRooms-green">
                      116,000.00
                    </p>
                  </div>
                  <div>
                    <p>Balance:</p>
                    <p>100,500.00</p>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CashierGuestDashboardRooms;
