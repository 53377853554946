import React from 'react'
import './Contact.css'
import ropa_logo from '../../assets/Layer_1.svg'
import ropa_text from '../../assets/Layer_2.svg'
import { Link } from 'react-router-dom'


const Contact = () => {

  
  
  
  

  
  
  

  return (
    <div className='main-contact hidden-down'>
      <div className='contact'>
        <div className="logo">
          <div className='cont-logo-insider'>
            <div className='cont-logo-img'>
            <img src={ropa_logo} alt="" className='logo-img'/>
            <img src={ropa_text} alt="" className='logo-text'/>
            </div>
            <p>Hotels and Apartments</p>
          </div>
        </div>
        
        
        
        <div className="container">
          
          
          <div className="list">
            <ul>
             <li className="green">Home</li>
             <li className="green">FAQ</li>
             <li className="green">Privacy Policy</li>
             <li className="green">Contact</li>
            </ul>
          </div>
          
          
          
          
          
          <div className="list">
            <ul>
             <li><Link className="blue" to="/">Home</Link></li>
             <li><Link className="red" to="/SignIn">Staff</Link></li>
             <li><Link className="blue" to="/Booking">Booking</Link></li>
             <li><Link className="red" to="/AdminLogin">Admin</Link></li>
            </ul>
          </div>
          
          
          
          
          
          
          {/* <div className="divider"></div>  New divider element */}
    
          
          
          <div className="address">
            <div className="contact-details">
             <h4>Have questions and suggestions?</h4>
             <p>inquiries@ropajo.com</p>
            </div>
            <div className="contact-details">
             <h4>Need assistance? Give us a call</h4>
             <p>+234 815 771 1115</p>
             <p>+234 704 001 4800</p>
            </div>
            <div className="contact-details">
             <h4>Our Location</h4>
             <p>Lobi Quarters, beside EFCC Office, Makurdi</p>
            </div>
          </div>
          
        </div>





      </div>
    </div>
  )
}

export default Contact
