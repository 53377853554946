/** @format */

import React from "react";
import "./Booking.css";
import DashBoardHeader from "../../DashBoardHeader/DashBoardHeader";
import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";

import { useState, useContext } from "react";
import { convertToBase64 } from "../../../utils/convertImg";
import { CgSpinner } from "react-icons/cg";
import toast from "react-hot-toast";
import { Store } from "../../../Store";



const Booking = () => {
  const { state, dispatch } = useContext(Store);
  const { staffInfo } = state;
  
  

  const [profileShow, setProfileShow] = useState(false);
  // form field state
  const [fullName, setFullName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [identityNumber, setIdentityNumber] = React.useState("");
  const [identityImage, setIdentityImage] = React.useState("");

  const [fileName, setFileName] = useState(""); // State for file name
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  
  
  
  const [viewMobileDashboard, setViewMobileDashboard] = useState(false);
  
  React.useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/auth/session`, {
          method: "GET", // Using GET method to retrieve data without sending a body
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
        });
      
        if (response.status === 200) {
          const data = await response.json();
          setProfileShow(!!data.token); // If a valid token exists, show the profile
          dispatch({ type: "ADMIN_SIGNIN", payload: data });
          // Set profileShow based on token availability
         
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    if (!staffInfo) {
      fetchUserSession();
    }
  }, [apiUrl, staffInfo, dispatch]);



  
  
  const uploadFile = async (e) => {
    const target = e.target;
    const { files } = target;
    const file = files[0];

    if (file) {
      setFileName(file.name); // Set the file name
      try {
        const base64 = await convertToBase64(file);
        setIdentityImage(base64); // Set the base64 string
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    }
  };
  
  
  
  const validateInputs = () => {
    if (!fullName || !email || !phoneNumber || !identityNumber || !identityImage) {
      toast.error("All fields are required.");
      return false;
    }

    // Validate email format
    const emailPattern = /^[^@]+@[^@]+\.[^@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Invalid email format.");
      return false;
    }

    // Validate phone number (example: should be numeric and of appropriate length)
    const phonePattern = /^\+?[0-9]{1,4}?[-.s]?[0-9]{1,4}?[-.s]?[0-9]{3,10}$/;
    if (!phonePattern.test(phoneNumber)) {
      toast.error("Invalid phone number format.");
      return false;
    }
    return true;
  };
  
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      toast.error("Please make sure all inputs are correctly inputed!");
      return;
    }
    
    
    
    setIsLoading(true); // Set loading state

    try {
      const response = await fetch(`${apiUrl}/api/bookings/guest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({
          fullName,
          email,
          phoneNumber,
          identityNumber,
          identityImage,
        }), // Include the necessary data in the request body
      });

      if (!response.ok) {
        // Check for HTTP errors
        const errorData = await response.json();
        const errorMessage = errorData.message || 'An error occurred';
        throw new Error(errorMessage);
      }

      // Handle successful response
      const data = await response.json();
      toast.success(`Booking successful, Your Booking ID is: ${data.bookingId}`);

    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Booking failed: ${error.response.data.message}`);
      } else {
        toast.error(`Booking failed: ${error.message}`);
    }
    } finally {
    setIsLoading(false); // Reset loading state
    }
    };








  return (
    <div className='booking'>
    
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
    
    
      <div className='book-hue'>
      
      
        <div>
          <DashboardRoomProfile
            profileShow={profileShow} 
            viewMobileDashboard={viewMobileDashboard} 
            setViewMobileDashboard={setViewMobileDashboard}
          />
          
          <DashBoardHeader
            profileShow={profileShow}
            setViewMobileDashboard={setViewMobileDashboard}
          />
        </div>
        
        


        <div className='book-body'>
          <form onSubmit={handleSubmit} className='book-form'>
            <h1>Booking</h1>
            <p>
              Please fill in your information below, we will contact you within
              24 hours
            </p>
            <input
              type='text'
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              placeholder='Full Names'
            />
            <input
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder='Email'
            />
            <input
              type='text'

              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder='Phone Number'
            />
            <input
              type='number'
              value={identityNumber}
              onChange={(e) => setIdentityNumber(e.target.value)}
              placeholder=' Identity No.'
            />
            <div>
              <span>Upload ID:</span>

              
              {fileName && <p className="truncate">{fileName}</p>}
              
              <input
                type='file'
                name=''
                id='file'
                onChange={(e) => uploadFile(e)}
                placeholder='Upload ID:'
                hidden
              />
              <label htmlFor='file' className='book-file-label'>
                upload
              </label>
            </div>
            
            <button type="submit" className='book-submit' disabled={isLoading}>
              {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
                "Submit"
              )}
            </button>
            

          </form>
        </div>
      </div>
    </div>
  );
};

export default Booking;
