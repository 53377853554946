import React, { useContext, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Store } from "../../../../Store";

import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";
import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import { CgSpinner } from "react-icons/cg";

import "./ReceptionistExpenses.css";



const ReceptionistExpenses = () => {
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  
  const navigate = useNavigate();
 
  const [category, setCategory] = useState("");

  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const { dispatch } = useContext(Store);
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const madeBy = retrievedInfo.fullName;
  
  
  const isValidJSON = (jsonString) => {
    try {
      JSON.parse(jsonString);
      return true;
    } catch (e) {
      console.error("Invalid JSON data:", e.message);
      return false;
    }
  }
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  

  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/ReceptionistExpenses", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/ReceptionistExpenses");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  const handleSubmit = async (e) => {
  e.preventDefault();
  
  
  if (!category) {
      toast.error("Select Category");
      return;
    }
    
  if (!description) {
      toast.error("Description is required");
      return;
    }
    
    
  if (!amount) {
      toast.error("Indicate Amount");
      return;
    }
    
  
    
  setIsLoading(true); // Set loading state

  const billNumber = Number(amount);
  
  
  const paymentData = {
      staff: retrievedInfo._id,
      madeBy,
      category,
      amount: billNumber,
      description,
    };
    
    
  
  
  try {
     
    
    
    const jsonString = JSON.stringify(paymentData);
    if (isValidJSON(jsonString)) {
      const response = await fetch(`${apiUrl}/api/payment/make-expenses`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: jsonString
    });

      if (!response.ok) {
      // Extract error details
      const errorData = await response.json();
      const errorMessage = errorData?.message || 'Failed to update expenses';
      throw new Error(errorMessage);
    } else {
      // Success response
      await response.json();
      toast.success("Expenses updated successfully");
      navigate(`/ReceptionistExpenses`);
      }
    } else {
       // Log the exact data causing the JSON parse error in a toast
       toast.error(`Invalid JSON data: ${jsonString}`);
       throw new Error("Invalid requestData format");
    }  

    
  } catch (error) {
    console.error("Error updating payment:", error);
    toast.error(error.message || "An unexpected error occurred");
  }
  finally {
    setIsLoading(false); // Reset loading state
    }
};
    
    
    
  
 

  
  
  
  return (
    <div className="CashierExpenses">
    
    
    
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
      
      
      
      <div className="CashierExpenses-hue">
      
      
        <div className="CashierExpenses-head">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>
        
        
        
        
        
       <div className="cashier-content-section">
       
        <div className="ReceptionAccount-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
        </div>
        
        
        
        
        
        <div className="CashierExpenses-body">
        
        
        
        
        
          <form
            action=""
            onSubmit={handleSubmit}
            className="CashierExpenses-form"
          >
            <div className="CashierExpenses-title">
             <h3>Expenses</h3>
              <p>Record Any Expenses You've Done</p>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                name="Category"
                id=""
              >
                <option value="Today">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
            </div>

            <textarea
              name=""
              id=""
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              cols={50}
              rows={4}
              placeholder="Description"
            ></textarea>
            <label className="CashierExpenses-label">
              Amount:
              <input
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                type="text"
                placeholder=""
              />
            </label>
            
            {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
            <button type="submit">Submit</button>
            )}
            
            
          </form>
          
          
        </div>
        </div>
      </div>
    </div>
  );
};

export default ReceptionistExpenses;
