import React, { useRef } from 'react'
import './DashboardRoomProfile.css'
// import { Link } from 'react-scroll'
import { Link } from 'react-router-dom'
import top_icon from '../../assets/top_arrow.svg'
import ropa_logo from '../../assets/mob-logo.svg'
// import profile_pic from '../../assets/profile_pic.png';





const DashboardRoomProfile = ({ profileShow, viewMobileDashboard, setViewMobileDashboard }) => {

  const profile = useRef(null);

  const closeDashboard =(e) => {
    if (e.target === profile.current) {
          setViewMobileDashboard(false);

    }
  }
  // const handleNavProfile = () => {
  //   setProfileShow(false);
  // };

  return (

    <div className={`dashboard-room-profile ${viewMobileDashboard ? "" : "hide"}`}>
      <div className='dbp-navbar'>
        <div className="dbp-arrow-up">
          <img src={top_icon} alt="" ref={profile} onClick={closeDashboard} />   

        </div>
        <div className="dbp-logo">
        <img src={ropa_logo} alt="" /> 
        </div>

        
        {profileShow && (
          <div className='profile-cont'>
            <h4>Peter A.A</h4>
            <p>Welcome Chief</p>
          </div>
        )}
        
        <div className="dbp-nav-list">
          <ul className="nav-links">
            {profileShow ? (
              <>
                <li><Link to='/GuestAccessPage'>Home</Link></li>
                <li><Link to='/GuestAccount'>Account</Link></li>
                <li><Link to='/GuestSignout'>Signout</Link></li>
              </>
            ) : (
              <>
                <li><Link to='/'>Home</Link></li>
                <li><Link to='/Booking'>Apartment</Link></li>
                <li><Link to='/Booking'>Room</Link></li>
                <li><Link to='/Booking'>Pool</Link></li>
              </>
            )}

          </ul>
        </div>
        
      </div>
    </div>
  )
}

export default DashboardRoomProfile
