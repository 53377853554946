/** @format */

import React, { useEffect, useReducer, createContext } from "react";

const initialState = {
  userInfo: undefined,

  adminInfo: undefined,
  staffInfo: undefined,

};

const reducer = (state, action) => {
  switch (action.type) {
    case "USER_SIGNIN":
      return { ...state, userInfo: action.payload };
    case "USER_SIGNOUT":
      return { ...state, userInfo: undefined };

    case "ADMIN_SIGNIN":
      return { ...state, adminInfo: action.payload };
    case "ADMIN_SIGNOUT":
      return { ...state, adminInfo: undefined };
    case "STAFF_SIGNIN":
      return { ...state, staffInfo: action.payload };
    case "STAFF_SIGNOUT":
      return { ...state, staffInfo: undefined };

    default:
      return state;
  }
};

const defaultDispatch = () => initialState;

const Store = createContext({
  state: initialState,
  dispatch: defaultDispatch,
});

const StoreProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo"))
      : undefined;

    const adminInfo = localStorage.getItem("adminInfo")
      ? JSON.parse(localStorage.getItem("adminInfo"))
      : undefined;
    const staffInfo = localStorage.getItem("staffInfo")
      ? JSON.parse(localStorage.getItem("staffInfo"))
      : undefined;

    if (userInfo) {
      dispatch({ type: "USER_SIGNIN", payload: userInfo });
    }
    if (adminInfo) {
      dispatch({ type: "ADMIN_SIGNIN", payload: adminInfo });
    }
    if (staffInfo) {
      dispatch({ type: "STAFF_SIGNIN", payload: staffInfo });
    }

  }, []);

  return <Store.Provider value={{ state, dispatch }} {...props} />;
};

export { Store, StoreProvider };
