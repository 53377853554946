/** @format */

import React, { useContext, useState, useEffect } from "react";
import "./BartenderManageGuest.css";
import { Link } from "react-router-dom";
import ReceptionistDashboard from "../BartenderProfile/ReceptionistDashboard";
import GuestInfoTableRow from "../BartenderGuestInfoTableRow/GuestInfoTableRow";
import ReceptionHeader from "../BartenderHeader/ReceptionHeader";
import { Store } from "../../../../Store";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";


const BartenderMangeGuest = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
 
  const { dispatch} = useContext(Store);
  
  
  const [guest, setGuest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/BartenderManageGuest", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/BartenderManageGuest");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  
 
  

  useEffect(() => {
    const fetchGuests = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/all-guest`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch guests.");
        }

        const data = await response.json();
        setGuest(data.data);
        toast.success("Guests fetched successfully!");
      } catch (err) {
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchGuests();
  }, [apiUrl]);

 

  
  
  
  
  
  
  
  
  
  
  

  return (
  <>
    <div className="ManageGuest">
      <div className="ManageGuest-web">
        <div className="ManageGuest-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>
        <div className="ManageGuest-content-section">
          <div className="ManageGuest-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
          </div>
          <div className="ManageGuest-content-head">
            <Link>Back to Homepage</Link>
          </div>
          <div className="ManageGuest-content-body">
            <div className="ManageGuest-bar-holder">
              <p>Guest Information</p>
              <input type="text" placeholder="Search Guests" />
            </div>
            <div className="ManageGuest-content-holder">
              <div className="ManageGuest-table-section">
                <div className="mg-web-table-holder">
                  <table className="mg-web-table">
                  
                    <thead className="BartenderManageGuest-thead">
                      <tr>
                        <th className="table-header name-columns">Name</th>
                        <th className="table-header unique-no-columns">Unique No.</th>
                        <th className="table-header contact-columns">Contact</th>
                      </tr>
                    </thead>
                    
                    <div className="ManageGuest-scroll">
                      {loading && (
                        <tr>
                          <td colSpan="3" className="loading-spinner">
                            <div className="spinner"></div>
                          </td>
                        </tr>
                      )}
                      {error && (
                        <tr>
                          <td colSpan="3" className="error-message">
                            <p>{error}</p>
                          </td>
                        </tr>
                      )}
                      {!loading &&
                        !error &&
                        guest.map((details, index) => (
                          <GuestInfoTableRow
                            names={details.fullName}
                            uniqueNo={details.userUniqueId}
                            phone={details.phoneNumber}
                            id={details._id}
                            index={index}
                            key={index}
                          />
                        ))}
                    </div>
                    
                    
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

};

export default BartenderMangeGuest;