/** @format */

import React, { useContext, useEffect } from "react";
import "./SignIn.css";
import DashBoardHeader from "../../DashBoardHeader/DashBoardHeader";
import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Store } from "../../../Store";
import { CgSpinner } from "react-icons/cg";

const SignIn = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  const [staffUniqueId, setStaffUniqueId] = React.useState("");
  
  const [profileShow, setProfileShow] = useState(false);
  const [viewMobileDashboard, setViewMobileDashboard] = useState(false);


  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;


  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  useEffect(() => {
  const fetchUserSession = async () => {
    try {
      const modelName = "AdminStaffModel"; // Replace with your actual model name
      const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
        method: "GET",
        credentials: "include", // Ensures cookies are sent with the request
      });

      if (response.status === 401) {
        console.log("Session expired, please log in again.");
        toast.error("Authorization Failed");
        return;
      }

      if (response.status === 200) {
        const data = await response.json();
        setProfileShow(true); // If a valid token exists, show the profile
        dispatch({ type: "STAFF_SIGNIN", payload: data });

        // Get redirect from secure cookie
        const redirect = getCookie("redirect");

        // Clear the redirect cookie after use
        document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

        // Validate redirect path to prevent open redirect vulnerabilities
        const validRedirects = ["/StaffDashboard", "/settings", "/profile"];
        const isValidRedirect = redirect && validRedirects.includes(redirect);

        // Handle navigation based on user roles
        if (data.active === true) {
            if (data.position) {
              switch (data.position) {
                case "Manager":
                  navigate("/ManagerDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Cashier":
                  navigate("/CashierDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Receptionist":
                  navigate("/StaffDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Waiter":
                  navigate("/BartenderDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                default:
                  navigate(isValidRedirect ? redirect : "/SignIn", {
                    state: { responseData: data },
                  });
                  toast.error("This Staff is not assigned a role yet");
                  break;
             }
             } else {
               navigate(isValidRedirect ? redirect : "/SignIn", {
                 state: { responseData: data },
               });
               toast.error("This Staff is not assigned a role yet");
             }
          } else {
            toast.error("Inactive Staff. Contact Administrator.");
          }
      } else {
        console.error(`Error: Received status code ${response.status}`);
        toast.error(`Error: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching user session:", error);
    }
  };

  fetchUserSession();
}, [apiUrl, navigate, dispatch]);

  
  
  
  
  
  
  
  
  
  
  
  
  
  const handleSubmit = async (e) => {
      
    e.preventDefault();

    // Ensure Guest ID is provided
    if (!staffUniqueId.trim()) {
      toast.error("Staff ID cannot be empty");
      return;
    }

    setIsLoading(true); // Set loading state to true
   
   
    try {
      const response = await fetch(`${apiUrl}/api/staff/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
        body: JSON.stringify({ staffUniqueId: staffUniqueId.trim() }),
      });
      
      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);
    
      if (!response.ok) {
        let errorMessage = `HTTP Error: ${response.status}`;

      // Attempt to parse the response JSON if available
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        console.warn("Failed to parse error response:", parseError);
      }

      throw new Error(errorMessage);
      }
      
      if (response.status === 200) {
        
        
        let data;
        try {
          data = await response.json();
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          toast.error("Failed to parse server response");
          return;
        }
        
        if (data) {  
          
          
          
          /*
          cookies.set('token', data.token, {
            httpOnly: true,
            secure: process.env.NODE_ENV === "production",
            sameSite: "Strict",
            maxAge: 3600, // Set cookie expiration (optional)
          });
          */
          
          localStorage.setItem('staffInfo', JSON.stringify(data));

          // Retrieve staffInfo from localStorage
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
            
          
          setProfileShow(true); // If a valid token exists, show the profile
          
          dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
          // Handle redirect
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/StaffDashboard", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);

          // Navigate to the validated redirect or default to /AdminDashboard
          if (data.active === true) {
            if (data.position) {
              switch (data.position) {
                case "Manager":
                  navigate("/ManagerDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Cashier":
                  navigate("/CashierDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Receptionist":
                  navigate("/StaffDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                case "Waiter":
                  navigate("/BartenderDashboard", {
                    state: { responseData: data },
                  });
                  toast.success("Login successful");
                  break;
                default:
                  navigate(isValidRedirect ? redirect : "/SignIn", {
                    state: { responseData: data },
                  });
                  toast.error("This Staff is not assigned a role yet");
                  break;
             }
             } else {
               navigate(isValidRedirect ? redirect : "/SignIn", {
                 state: { responseData: data },
               });
               toast.error("This Staff is not assigned a role yet");
             }
          } else {
            toast.error("Inactive Staff. Contact Administrator.");
          }

      } else {
        console.error("Missing access token in response:", data);
        toast.error("Login response invalid");
      }
    } else {
      console.error("Login failed with status:", response.status);
      toast.error("Login failed");
    }
    } catch (err) {
      console.error("Login failed:", {
        message: err.message,
        stack: err.stack,
        originalError: err,
    });
    
      // Display the error message
      const errorMessage = err.message || "An error occurred";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false); // Reset loading state after request completes
      }
    };
  
  
  
  
  
  return (
    <div className="signIn">
    
    
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
    
      <div className="signIn-hue">
      
      
        <div>
          <DashboardRoomProfile
            profileShow={profileShow} 
            viewMobileDashboard={viewMobileDashboard} 
            setViewMobileDashboard={setViewMobileDashboard}
          />
          
          <DashBoardHeader
            profileShow={profileShow}
            setViewMobileDashboard={setViewMobileDashboard}
          />
        </div>
        
        
        
        <div className="signIn-body">
          <form onSubmit={handleSubmit} className="signIn-form">
            <h1>Sign in</h1>
            <div className="signIn-hide-box"></div>
            <input
              type="text"
              onChange={(e) => setStaffUniqueId(e.target.value)}
              id=""
              placeholder="Staff  Unique ID"
            />

          
              <button type="submit" className="signIn-submit" disabled={isLoading}>
              
                {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
                "Sign In"
              )}
              
              </button>
           
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
