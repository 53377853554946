
import React, { useContext, useState, useEffect } from "react";
import minc_card from "../../../assets/Nimc_profile.jpg";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import "./StaffInformation.css";
import toast from "react-hot-toast";
import { Store } from "../../../Store";
import { useParams, useNavigate } from "react-router-dom";


const StaffInformation = () => {
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  const navigate = useNavigate();
  
  const { id } = useParams();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  // const { state: { adminInfo }, dispatch } = useContext(Store);
  const { dispatch } = useContext(Store);
  
  const [staff, setStaff] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(""); // "salary" or "status"
  const [salary, setSalary] = useState("");
 
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  

  
  
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/AdminLogin");
          toast.error("Authorization Failed");
          console.log("Session expired, please log in again.");
          return;
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('adminInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('adminInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.adminUniqueId === data.adminUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "ADMIN_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = [`/StaffInformation/${id}`, "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);
            
            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : `/StaffInformation/${id}`);
          } else {
            navigate("/AdminLogin");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/AdminLogin");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch, id]);
  
  
  
  
  
  
  
  
  React.useEffect(() => {
    const fetchStaff = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/admin-staff/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setStaff(data.staff);
        toast.success("Staff data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    if (id) {
      fetchStaff();
    } else {
      setError("Invalid staff ID");
      toast.error("Invalid staff ID");
      navigate('/StaffList')
    } 
  }, [id, apiUrl, navigate]);

 

 
  
  
  const updateProfile = async (updatedData) => {
    try {
      setIsLoading(true);
      setError(null);

      const response = await fetch(`${apiUrl}/api/admin-staff/update/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedData),
        credentials: "include",
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update staff.");
      }

      const data = await response.json();
      console.log(`Data: ${data}`);
      toast.success("Staff details updated successfully!");
      navigate(0); // Refresh the current page

    } catch (err) {
      console.error("Update error:", err.message);
      setError(err.message);
      toast.error(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  const handleEditSalary = () => {
    setModalType("salary");
    setIsModalOpen(true);
  };

  const toggleActivityStatus = () => {
    setModalType("status");
    setIsModalOpen(true);
  };

  

  const handleModalConfirm = async (value) => {
    if (modalType === "salary") {
      await updateProfile({ salary: salary });
    } else if (modalType === "status") {
      await updateProfile({ active: !staff.active });
    }
    setIsModalOpen(false);
  };
  
  
  
  
  
  

  
  
  
  
  
  
  
  

  return (
    <div className="StaffInformation">
      {isLoading && (
        <div className="loading-spinner">
          <div className="spinner"></div>
        </div>
      )}
      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}
      
      
      
      <div className="StaffInformation-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      
      

      <div className="StaffInformation-space">
        <div className="StaffInformation-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>

        
        

        <div className="StaffInformation-space-body">
          <section className="StaffInformation-board">
            <div className="StaffInformation-center-board">
              <div className="StaffInformation-center-cont ContLeft">
                <div>

                  <h2>{staff?.fullName}</h2>
                  <p>{staff?.phoneNumber}</p>
                </div>
                
                
                <div className="StaffInformation-info-section">
                
                

                






                  <h2>Staff ID: {staff?.staffUniqueId}</h2>
                  <div className="StaffInformation-information">
                    <p>
                      <b>Address:</b> {staff?.address}
                    </p>
                    <p>
                      <b>Email:</b> {staff?.email}
                    </p>
                    <p>
                      <b>DOB:</b> {staff?.dateOfBirth}
                    </p>
                    <p>
                      <b>Gender:</b> {staff?.gender}
                    </p>
                    <p>
                      <b>Qualification:</b> {staff?.qualification}
                    </p>
                    <p>
                      <b>Position:</b> {staff?.position}
                    </p>
                  </div>
                </div>
                
                



                <div className="StaffInformation-cont-btn">
        
                  <button onClick={handleEditSalary}>Edit Salary</button>
        
                 <button onClick={toggleActivityStatus}>
                   {staff?.active === true ? "Stop Duties" : "Start Duties"}
                 </button>
                 
                </div>
                
                

      {/* Edit Salary Modal */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            {modalType === "salary" ? (
              <>
                <h3>Edit Salary</h3>
                <input
                  type="number"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  placeholder="Enter new amount"
                />
              </>
            ) : (
              <h3>
                Are you sure you want to {staff?.active === true ? "stop duties" : "start duties"}?
              </h3>
            )}
            <div className="modal-buttons">
              <button onClick={() => setIsModalOpen(false)}>Cancel</button>
              
              
              <button onClick={() =>
                modalType === "salary"
                ? handleModalConfirm(salary) // Pass input value directly
                : handleModalConfirm(!staff?.active) // Pass active status directly
               }
              >
                {modalType === "salary" ? "Update" : "OK"}
              </button>
              
              
            </div>
          </div>
        </div>
      )}
      
      

      <div className="StaffInformation-mobile-nimc">
        <img src={minc_card} alt="" />
      </div>
    </div>
 

              

              <div className="StaffInformation-center-cont ContRight">
                <div className="StaffInformation-contRight-up">
                  <div className="">
                    <h2>Salary</h2>

                    <p>{staff?.salary}</p>
                   
                  </div>
                  <img src={staff?.photo} alt="" />
                </div>
                <div className="StaffInformation-contRight-down">
                  <img src={staff?.idPhoto} alt="" />

                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffInformation;
