
import "./AdminLogin.css";
import DashBoardHeader from "../../DashBoardHeader/DashBoardHeader";
import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";
import { Store } from "../../../Store";
import toast from "react-hot-toast";
import React, { useContext, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";


const AdminLogin = () => {
  const navigate = useNavigate();
  // const { state, dispatch } = useContext(Store);
  const { dispatch } = useContext(Store);
  // const { adminInfo } = state;
  const [profileShow, setProfileShow] = useState(false);
  const [adminUniqueId, setAdminUniqueId] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  const [viewMobileDashboard, setViewMobileDashboard] = useState(false);
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  React.useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
          return;
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          setProfileShow(true); // If a valid token exists, show the profile
          dispatch({ type: "ADMIN_SIGNIN", payload: data });
          // Set profileShow based on token availability
          
          
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/AdminDashboard", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);

          // Navigate to the validated redirect or default to /AdminDashboard
          navigate(isValidRedirect ? redirect : "/AdminDashboard");
         
        } else {
          // Log all other status codes
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
          return;
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, dispatch, navigate]);

  
  
  const handleSubmit = async (e) => {
      
    e.preventDefault();

    // Ensure Guest ID is provided
    if (!adminUniqueId.trim()) {
      toast.error("Guest ID cannot be empty");
      return;
    }

    setIsLoading(true); // Set loading state to true
   
   
    try {
      const response = await fetch(`${apiUrl}/api/admin/signin`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
        body: JSON.stringify({ adminUniqueId: adminUniqueId.trim() }),
      });
      
      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);
    
      if (!response.ok) {
        let errorMessage = `HTTP Error: ${response.status}`;

      // Attempt to parse the response JSON if available
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        console.warn("Failed to parse error response:", parseError);
      }

      throw new Error(errorMessage);
      }
      
      if (response.status === 200) {
        
        
        let data;
        try {
          data = await response.json();
        } catch (error) {
          console.error("Error parsing JSON response:", error);
          toast.error("Failed to parse server response");
          return;
        }
        
        if (data) {  
          
          
          
          /*
          cookies.set('token', data.token, {
            httpOnly: true,
            secure: process.env.NODE_ENV === "production",
            sameSite: "Strict",
            maxAge: 3600, // Set cookie expiration (optional)
          });
          */
          
          
          localStorage.setItem('adminInfo', JSON.stringify(data));

          // Retrieve staffInfo from localStorage
          const storedStaffInfo = JSON.parse(localStorage.getItem('adminInfo'));
            
          
          setProfileShow(true); // If a valid token exists, show the profile
          
          dispatch({ type: "ADMIN_SIGNIN", payload: storedStaffInfo });
          
          
          setProfileShow(true); // If a valid token exists, show the profile
          toast.success("Login successful");
     
        
          // Handle redirect
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/AdminDashboard", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);

          // Navigate to the validated redirect or default to /AdminDashboard
          navigate(isValidRedirect ? redirect : "/AdminDashboard", {
            state: { responseData: data },
          });
        } else {
          console.error("Missing access token in response:", data);
          toast.error("Login response invalid");
        }
      } else {
        console.error("Login failed with status:", response.status);
        toast.error("Login failed");
      }
    } catch (err) {
      console.error("Login failed:", {
        message: err.message,
        stack: err.stack,
        originalError: err,
    });
    
      // Display the error message
      const errorMessage = err.message || "An error occurred";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false); // Reset loading state after request completes
      }
    };
  
  
  
  return (
    <div className="AdminLogin">
    
    
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
    
    
    
      <div className="AdminLogin-hue">
        
        <div>
          <DashboardRoomProfile
            profileShow={profileShow} 
            viewMobileDashboard={viewMobileDashboard} 
            setViewMobileDashboard={setViewMobileDashboard}
          />
          
          <DashBoardHeader
            profileShow={profileShow}
            setViewMobileDashboard={setViewMobileDashboard}
          />
        </div>
        
        
        <div className="AdminLogin-body">
          <form onSubmit={handleSubmit} className="AdminLogin-form">
            <h1>Sign in</h1>
            <div className="AdminLogin-hide-box"></div>
            <input value={adminUniqueId}
              onChange={(e) => setAdminUniqueId(e.target.value)}
              type="text" 
              name="" id="" 
              placeholder="Admin Unique ID" />
              
              <button type="submit" className="AdminLogin-submit" disabled={isLoading}>
                {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
                "Sign In"
              )}
            </button>
            
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminLogin;
