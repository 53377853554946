import React, { useState } from "react";
import "./CashierAccount.css";


import CashierAccountList from "./CashierAccountList/CashierAccountList";


import ReceptionistDashboard from "../CashierProfile/ReceptionistDashboard";
import ReceptionHeader from "../CashierHeader/ReceptionHeader";



import { useGetStaffsQuery } from "../../../../hooks/staff";


import { useGetPaymentsQuery } from "../../../../hooks/payment";
import { formatDistanceToNow } from "date-fns";

const CashierAccount = () => {
  const { data: AdminAccountitems, isPending: isFetchingPayments } =
    useGetPaymentsQuery();
  const { data: staff, isPending: isFetchingStaffs } = useGetStaffsQuery();
  
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  const [selectedDate, setSelectedDate] = useState(""); // State for selected date
  const [categoryFilter, setCategoryFilter] = useState(""); // State for category filter

  // Reset filters
  const handleResetFilters = () => {
    setCategoryFilter("");
    setSelectedDate("");
  };

  // Filter items based on selected date
  const filteredItems = AdminAccountitems?.filter((item) => {
    const matchesCategory = categoryFilter
      ? item.category.toLowerCase().includes(categoryFilter.toLowerCase())
      : true;

    const itemDate = new Date(item.createdAt); // Convert item date to a Date object

    let matchesDate = true; // Default is true if no date is selected

    if (selectedDate) {
      const selectedDateObj = new Date(selectedDate);
      matchesDate = itemDate.toDateString() === selectedDateObj.toDateString(); // Match if it's the same date
    }

    return matchesCategory && matchesDate;
  });

  // Calculate totals
  const totalBill =
    filteredItems?.reduce((sum, item) => sum + item.bill, 0) || 0;
  const totalPaid =
    filteredItems?.reduce((sum, item) => sum + item.paid, 0) || 0;
  const totalBalance = totalBill - totalPaid;



  return (
    <div className="CashierAccount">
    
      <div className="CashierAccount-profile">
        <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      
      <div className="CashierAccount-space">
        <div className="CashierAccount-mobile-header">
          <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
        </div>
        
        
        <div className="CashierAccount-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="CashierAccount-space-body">
          <div className="CashierAccount-title-board">
            <p>Income</p>
            <div className="CashierAccount-title-select">
              {/* Filters */}
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                style={{ color: "black", padding: 7 }}
              />

              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                name="Category"
                id=""
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>

              <button onClick={handleResetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
          <section className="CashierAccount-board">
            <div className="CashierAccount-center-board">
              <div className="CashierAccount-table-head">
                <p>Staff Name</p>
                <p>Category</p>
                <p>Bill</p>
                <p>Paid</p>
                <p>Balance</p>
                <p>Date</p>
              </div>
              <div className="CashierAccount-table-body">
                {isFetchingPayments || isFetchingStaffs ? (
                  <span style={{ color: "black" }}>loading...</span>
                ) : (
                  filteredItems?.map((item, index) => {
                    const staffMember = staff?.find(
                      (s) => s.staffUniqueId === item.collectedBy
                    );

                    const balance = item.bill - item.paid;
                    const formattedDate = formatDistanceToNow(
                      new Date(item.createdAt),
                      {
                        addSuffix: true,
                      }
                    );

                    return (
                      <CashierAccountList
                        key={index}
                        name={staffMember?.fullName || "Unknown"}
                        category={item.category}
                        bill={item.bill}
                        paid={item.paid}
                        balance={`${balance}.00`}
                        date={formattedDate}
                        index={index}
                      />
                    );
                  })
                )}
              </div>
              <div className="AdminAccounts-totals">
                <h5>Bill: {totalBill}</h5>
                <h5>Paid: {totalPaid}</h5>
                <h5>Balance: {totalBalance}</h5>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CashierAccount;
