import React, { useState, useContext, useEffect } from "react";
import "./BartenderDashboard.css";
import ReceptionistDashboard from "../BartenderProfile/ReceptionistDashboard";
import ReceptionHeader from "../BartenderHeader/ReceptionHeader";
import toast from "react-hot-toast";
import { Store } from "../../../../Store";

import { useNavigate, Link } from "react-router-dom";



const BartenderDashboard = () => {
  //const [barProfileShow, setBarProfileShow] = useState(false);
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/BartenderDashboard", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/BartenderDashboard");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  
  
  return (
    <div className="BartenderDashboard">
    <div className="BartenderDashboard-web">
    
      <div className="BartenderDashboard-profile">
        <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      
      <div className="BartenderDashboard-space">
        <div className="BartenderDashboard-mobile-header">
          <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
        </div>
        
        
        
        
        
        <div className="BartenderDashboard-space-head">
          <p>Back to Homepage</p>
        </div>
        
        
        <div className="BartenderDashboard-space-body">
        
        
          <div className="BartenderDashboard-board-up">
            <p>Activities</p>
            
            
            <div className="BartenderDashboard-board-up-container">
              {/* <button><Link to='/OpenSales'>Open Sales</Link> </button> */}
              <button>
                <Link to="/BartenderManageGuest">Guest</Link>{" "}
              </button>
              <button>
                <Link to="/BarRegisterGuests">Register Guest</Link>{" "}
              </button>
              {/* <button></button> */}
            </div>
            
            
          </div>
          
          
        </div>
        
        
      </div>
      </div>
    </div>
  );
};

export default BartenderDashboard;
