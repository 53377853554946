/** @format */

import React, { useContext, useState, useEffect } from "react";
import "./RegisterGuests.css";


import ReceptionHeader from "../../Staff-Receptionist/ReceptionHeader/ReceptionHeader";
import ReceptionistDashboard from "../../Staff-Receptionist/ReceptionistDashboard/ReceptionistDashboard";



import { convertToBase64 } from "../../../../utils/convertImg";

import toast from "react-hot-toast";

import { CgSpinner } from "react-icons/cg";
import { generateUniqueNumbers } from "../../../../utils/generateId";
import { useNavigate } from "react-router-dom";
import { Store } from "../../../../Store";









const RegisterGuests = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);

  const navigate = useNavigate();

  const [fullName, setFullName] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [occupation, setOccupation] = React.useState("");
  const [carRegNumber, setCarRegNumber] = React.useState("");
  const [arriverDate, setArriverDate] = React.useState("");
  const [arriverFrom, setArriverFrom] = React.useState("");
  const [guestIdPhoto, setGuestIdPhoto] = React.useState("");
  const [nationality, setNationality] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [passportNumber, setPassportNumber] = React.useState("");
  const [purposeOfTravel, setPurposeOfTravel] = React.useState("");
  const [departureDate, setDepartureDate] = React.useState("");
  const [departTo, setDepartTo] = React.useState("");

  const unique = generateUniqueNumbers(1, 4)[0];

  const userUniqueId = `R${unique}x`;
  
  const [fileName, setFileName] = useState("");
  const { dispatch } = useContext(Store);
  
  
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/RegisterGuests", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/RegisterGuests");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  const validateFileSize = (file) => {
    const maxSize = 15 * 1024 * 1024; // 15 MB in bytes
    return file.size <= maxSize;
  };
  
  
  
  
  
  
  
  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (file && validateFileSize(file)) {
      setFileName(file.name); // Set the file name
      try {
        const base64 = await convertToBase64(file);
        setGuestIdPhoto(base64); // Set the base64 string
      } catch (error) {
        console.error("Error compressing image:", error);
      }
  } else {
    setFileName('');
    toast.error("Photo size must not exceed 16MB.");
  }
};





  const validateInputs = () => {
    if (!fullName || !nationality || !phoneNumber) {
      toast.error("Names, nationality and Phone Number required.");
      return false;
    }

    // Validate email format
    if (email) {
      const emailPattern = /^[^@]+@[^@]+\.[^@]+$/;
      if (!emailPattern.test(email)) {
        toast.error("Invalid email format.");
        return false;
      }
    }


    // Validate phone number (example: should be numeric and of appropriate length)
    const phonePattern = /^\+?[0-9]{1,4}?[-.s]?[0-9]{1,4}?[-.s]?[0-9]{3,10}$/;
    if (!phonePattern.test(phoneNumber)) {
      toast.error("Invalid phone number format.");
      return false;
    }
    return true;
  };
  
  
  
  
  
  const countries = [
    { name: "Select Country", code: "" },
    { name: "Nigeria", code: "+234" },
    { name: "United States", code: "+1" },
    { name: "United Kingdom", code: "+44" },
    { name: "India", code: "+91" },
    // Add more countries as needed
  ];

  const handleNationalityChange = (e) => {
    const selectedCountry = countries.find(
      (country) => country.name === e.target.value
    );
    setNationality(selectedCountry.name);
    setPhoneNumber(selectedCountry.code);
  };
  
  
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      toast.error("Please make sure all inputs are correctly inputed!");
      return;
    }
    
    setIsLoading(true); // Set loading state

    try {
      const response = await fetch(`${apiUrl}/api/manage-guest/add-guest`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({
          fullName,
          address: address || "Nill",
          email: email || "Nill",
          occupation: occupation || "Nill",
          carRegNumber: carRegNumber || "Nill",
          arriverDate: arriverDate || "Nill",
          arriverFrom: arriverFrom || "Nill",
          guestIdPhoto: guestIdPhoto || "Nill",
          Nationality: nationality || "Nill",
          phoneNumber,
          passportNumber: passportNumber || "Nill",
          purposeOfTravel: purposeOfTravel || "Nill",
          departureDate: departureDate || "Nill",
          departTo: departTo || "Nill",
          userUniqueId,
        }), // Include the necessary data in the request body
      });

      if (response.ok) {
        toast.success("Guest Registration Done!");
        console.log("Navigating to OnlineBookingList");
        navigate("/OnlineBookingList");
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || "Unknown error occurred");
      }
      
    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Guest Registration Failed: ${error.response.data.message}`);
      } else {
        toast.error(`Guest Registration Failed: ${error.message}`);
    }
    } finally {
    setIsLoading(false); // Reset loading state
    }
    };






  return (
    <div className="registerGuests">
    
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
    
    
    
      <div className="registerGuests-hue">
      
      
      
     
        <div className="registerGuests-head">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>
        
      
        
        <div className="registerDashboard-content-section">
        
        <div className="registerGuests-mobile-head">
          <ReceptionistDashboard
                receptionProfileShow={receptionProfileShow}
                setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader
             setReceptionProfileShow={setReceptionProfileShow}
          />
        </div>
        
        
        
        
        
        <div className="registerGuests-body">
        
        
         
        
        
        
          <div className="registerGuests-body-content-holder">
            <h1>Register Guests</h1>
            <form onSubmit={handleSubmit} className="registerGuests-form">
              <div className="registerGuests-form-holder">
                {/* <div></div> */}
                <div className="registerGuests-half-text-enter">
                  <label>Guest Name:</label>
                  <input
                    onChange={(e) => setFullName(e.target.value)}
                    value={fullName}
                    type="text"
                    placeholder=""
                    className="registerGuests-form50p"
                  />
                </div>
                
                <div className="registerGuests-form50p registerGuests-passport">
                  <span>Upload ID Card:</span>
                  
                  {fileName && <p className="truncate">{fileName}</p>}
                  
                  
                  <input
                    type="file"
                    onChange={(e) => uploadPhoto(e)}
                    id="registerGuests-passport"
                    placeholder="Upload ID:"
                    hidden
                  />
                  <label
                    htmlFor="registerGuests-passport"
                    className="registerGuests-file-label"
                  >
                    upload
                  </label>
                </div>
                
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Address:</label>
                  <input
                    type="text"
                    placeholder=""
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    className="registerGuests-form50p"
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                
                
                  <label>Nationality:</label>
                  <select
                      value={nationality}
                      onChange={handleNationalityChange}
                      className="registerGuests-form50p"
                    >
                      {countries.map((country, index) => (
                        <option key={index} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                  </select>
                  
                  
                </div>
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Email:</label>
                  <input
                    type="email"
                    placeholder=""
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                
                
                  <label>Phone:</label>
                  <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="registerGuests-form50p"
                  />
                  
                  
                </div>
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Occupation:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={occupation}
                    onChange={(e) => setOccupation(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                  <label>Passport No:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={passportNumber}
                    onChange={(e) => setPassportNumber(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Car Reg No:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={carRegNumber}
                    onChange={(e) => setCarRegNumber(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                  <label>Purpose of Travel:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={purposeOfTravel}
                    onChange={(e) => setPurposeOfTravel(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Arrival Date:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={arriverDate}
                    onChange={(e) => setArriverDate(e.target.value)}
                    className="registerGuests-form50p"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                  <label>Departure Date:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={departureDate}
                    onChange={(e) => setDepartureDate(e.target.value)}
                    className="registerGuests-form50p"
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                  />
                </div>
              </div>
              <div className="registerGuests-form-holder">
                <div className="registerGuests-half-text-enter">
                  <label>Arrival From:</label>
                  <input
                    type="text"
                    value={arriverFrom}
                    onChange={(e) => setArriverFrom(e.target.value)}
                    placeholder=""
                    className="registerGuests-form50p"
                  />
                </div>
                <div className="registerGuests-half-text-enter">
                  <label>Depart To:</label>
                  <input
                    type="text"
                    placeholder=""
                    value={departTo}
                    onChange={(e) => setDepartTo(e.target.value)}
                    className="registerGuests-form50p"
                  />
                </div>
              </div>

              <div className="registerGuests-form-holder">
              
                {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
                  <button
                    type="submit"
                    className="registerGuests-form-100p"
                  >
                   Register
                  </button>
                )}
                
              </div>
            </form>
            
          
          </div>
          
          
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterGuests;
