/** @format */

import React, { useContext, useState, useEffect } from "react";
import "./AdminManageGuest.css";


import AdminProfile from "../AdminProfile/AdminProfile";
import AdminHeader from "../AdminHeader/AdminHeader";



import { CgSpinner } from "react-icons/cg";

import { Store } from "../../../Store";

import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";








const AdminManageGuest = () => {
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  const [submittingGuestId, setSubmittingGuestId] = useState(null); 
  
  const { dispatch} = useContext(Store);
  
 
  const [guest, setGuest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  const MAX_RETRIES = 20; // Limit the number of retries (in 100ms intervals)
  let retries = 0;
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/AdminLogin");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('adminInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "ADMIN_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/AdminManageGuest", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/AdminManageGuest");
          } else {
            navigate("/AdminLogin");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/AdminLogin");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  

  useEffect(() => {
    const fetchGuests = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/all-guest`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch guests.");
        }

        const data = await response.json();
        setGuest(data.data);
        toast.success("Guests fetched successfully!");
      } catch (err) {
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchGuests();
  }, [apiUrl]);

 
  
  
  const fetchRoomDetails = async (identifier) => {
      // if (!identifier) return;

      setLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/rooms/view-room/${identifier}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch guest details";
          console.log(errorMessage);
        }

        const data = await response.json();
        
        return data.data; // Return the fetched room details

        // Show success toast
        // toast.success("Room details fetched successfully!");
      } catch (error) {
        setError(error.message);
        return true;
        // Show error toast
        // toast.error(error.message || "An unexpected error occurred");
      } finally {
        setLoading(false);
      }
    };
    
    
    
  
  
  const updateRoom = async (item_id) => {
  try {
    // Prepare the payload
    const roomId = item_id;
    const payload = {
      payload: { isAvailable: true, type: "" },
    };

    // Make the API call
    const response = await fetch(`${apiUrl}/api/rooms/checkout-room/${roomId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Failed to update room with ID ${item_id}`);
    }

    // Parse and return the result
    const result = await response.json();
    console.log(result );
    // Handle success
    toast.success("Room updated successfully!");
    
    // Refresh the page after a short delay to allow the toast to be displayed
    setTimeout(() => {
      window.location.reload();
      }, 2000); // Adjust delay as needed
      
    return true;
  } catch (error) {
    // Handle errors
    console.error("Error updating room:", error);
    toast.error(`Error: ${error.message}`);
    throw error;
  }
};


  
  const handleDelete = async (id, identifier) => {
  
  setSubmittingGuestId(id);
  
  
  try {
      
    const resp = await fetch(`${apiUrl}/api/payment/user-payments/${id}`);
    
    
    
    if (!resp.ok) {
      const errorMessage = await resp.text();
      throw new Error(`Failed to fetch payments: ${errorMessage}`);
    }
    
    // const data = await resp.json();
    // const updatedPayments = data.data;
    
    
    // Filter payments that have not been checked out
    // const outstandingPayments = updatedPayments.filter((pay) => (pay.bill - pay.paid) > 0);
    
    
  
    /*
    if (outstandingPayments.length > 0) {
      toast.error("Customer still have outstanding payments!");
      setIsSubmitting(false);
      return;
    }
     */
     
     
    // Call the delete API if no outstanding payments
    const response = await fetch(`${apiUrl}/api/manage-guest/delete/${identifier}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const result = await response.json();

    if (!response.ok) {
      toast.error(result.message || "Failed to delete Customer.");
      throw new Error(result.message || "Failed to delete Customer.");
    }
    
    
    let room = null;
    
    while (!room && retries < MAX_RETRIES) {
      room = await fetchRoomDetails(identifier);
      if (!room) {
        await new Promise((resolve) => setTimeout(resolve, 100)); // Wait for 100ms
        retries++;
    }
  }
    
    
    
    if (room?.length) {
      const unavailableRooms = room.filter((rooms) => !rooms.isAvailable);

      await Promise.all(
        unavailableRooms.map(async (rooms) => {
          try {
            await updateRoom(rooms._id);
          } catch (error) {
            toast.error(`Failed to update room with ID ${rooms._id}`, error)
            console.error(`Failed to update room with ID ${rooms._id}`, error);
          }
        })
       );
    }
    
    // Notify the user of successful deletion
    toast.success(result.message || "All Transactions with this Customer is ended successfully!");
    
    
    setTimeout(() => {
      navigate("/AdminManageGuest");
    }, 2000);
    
    
  } catch (error) {
    // Handle errors and show detailed messages
    console.error("Error processing payments:", error);
    toast.error(`Error: ${error.message}`);
    throw error;
  } finally {
     setSubmittingGuestId(false);
    }
};


  
  
  
  
  
  
  
  

  return (
    <div className="StaffDashboard">
      <div className="StaffDashboard-web">
      
        <div className="StaffDashboard-guest-section">
          <AdminProfile adminProfileShow={true} />
        </div>

        <div className="StaffDashboard-content-section">
          <div className="StaffDashboard-mobile-header">
            <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
          </div>

          <div className="StaffDashboard-content-body">
            <p>Guests</p>

            <div className="StaffDashboard-rooms">
              {error && <p className="error-message">Error: {error}</p>}
                {loading ? (
                   <div className="loader"></div>
                       ) : guest?.length ? (
                         <div className="rooms-table-container">
                            <table className="rooms-table">
                              <thead>
                                <tr>
                                  <th>Names</th>
                                  <th>Unique ID</th>
                                  <th>Status</th>
                                </tr>
                              </thead>
                              <tbody>
                                {guest.map((guests) => (
                                 <tr key={guests._id}>
                                  <td>{guests.fullName.split(" ")[0]}</td>
                                  <td>{guests.userUniqueId}</td> {/* Added Price */}
                                  <td>
                      
                                      <button
                                        className="delete-room-button"
                                        onClick={() => handleDelete(guests._id, guests.userUniqueId)}
                                      >
                                       {submittingGuestId === guests._id ? (
                                <CgSpinner className="spinner" />
                              ) : (
                                "Delete"
                              )}
                                      </button>
                               
                                  </td>
                                 </tr>
                                ))}
                              </tbody>
                             </table>
                         </div>
                        ) : (
                          <p>No Guests.</p>
                        )}
            </div>
            
            </div>
        </div>
      </div>
    </div>
  );
};

export default AdminManageGuest;

