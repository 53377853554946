
import html2pdf from "html2pdf.js"; // Import the library
import hat from "../../assets/cap-brown.svg";
import logo from "../../assets/mob-logo.svg";


import { CgSpinner } from "react-icons/cg";
import React, { useContext, useRef, useEffect, useState } from "react";
import { DateFormatter } from "../../utils/DateFOrmatter";
import "./Receipt.css";

import { Store } from "../../Store";
import { useParams, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";



const Receipt = () => {
  const { identity } = useParams();
  const receiptRef = useRef(); // Create a reference for the receipt
  
  
 
  const [loading, setLoading] = useState(false);
  
  const [isLoading, setIsLoading] = useState(false);
  const [guest, setGuest] = useState([]);
  
  /*
  const [identityy, setIdentityy] = useState([]);
  const [indexx, setIndexx] = useState(null);
  const [names, setNames] = useState(null);
  */
  
  
  const [paymentData, setPaymentData] = useState([]);
  const [roomData, setRoomData] = useState(null);
  
  
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [error, setError] = useState(null);
  const { dispatch } = useContext(Store);
  
  const retrievedInfo = JSON.parse(localStorage.getItem("staffInfo")) || {};
  const staffName = retrievedInfo.fullName || 'N/A';

  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  
  
  
    
    
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('staffInfo', JSON.stringify(data));
          
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.staffUniqueId === data.staffUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/Receipt/:identity", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/Receipt/:identity");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  
  

  useEffect(() => {
  
    const fetchGuest = async (identifier) => {
      // if (!uniqueNo) return;
      
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/manage-guest/single-guest/${identifier}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setGuest(data.data);
        toast.success("Guest data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        // setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    
    

    const fetchRoomDetails = async (identifier) => {
      // if (!identifier) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/rooms/view-room/${identifier}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch guest details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setRoomData(data.data);

        // Show success toast
        toast.success("Room details fetched successfully!");
      } catch (error) {
        // setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
  
  
    const fetchPayments = async (index) => {
      // if (!uniqueNo) return;

      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${apiUrl}/api/payment/user-payments/${index}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          const errorMessage =
            errorData?.message || "Failed to fetch payment details";
          throw new Error(errorMessage);
        }

        const data = await response.json();
        setPaymentData(data.data);

        // Show success toast
        toast.success("Payments fetched successfully!");
      } catch (error) {
        // setError(error.message);

        // Show error toast
        toast.error(error.message || "An unexpected error occurred");
      } finally {
        setIsLoading(false);
      }
    };
    
    
    const handleFetch = async () => {
    if (identity) {
      const savedProps = JSON.parse(localStorage.getItem("guestDashboardProps")) || {};
      if (savedProps.indexx && savedProps.identity) {
        // Use savedProps directly for immediate availability
        const identifier = savedProps.identity || "N/A";
        const index = savedProps.indexx || "N/A";
        // const name = savedProps.name || "N/A";

        /* Update state after ensuring the values are ready
        setIdentityy(identifier);
        setIndexx(index);
        setNames(name);
        */

        // Pass the values directly to fetchGuest if needed
        await fetchGuest(identifier);
        await fetchRoomDetails(identifier);
        await fetchPayments(index)
      } else {
        toast.error("Invalid guest dashboard data!");
      }
    } else {
      navigate("/ManageGuest");
      toast.error("Please select a customer!");
    }
  };

  handleFetch();
    
}, [apiUrl, identity, navigate]);














  // Manual print function triggered by the button
  const handleSend = async () => {
  setLoading(true);

  if (receiptRef.current) {
    const opt = {
      margin: 1,
      filename: "ropajo.pdf",
      html2canvas: { scale: 3 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };

    try {
      // Generate the PDF as a Blob
      const pdfBlob = await html2pdf().set(opt).from(receiptRef.current).outputPdf("blob");

      // Create a FormData object to send the file, customer name, and customerID
      const formData = new FormData();
      formData.append("file", pdfBlob, "ropajo.pdf");
      formData.append("customerID", guest?.userUniqueId || "guest");

      // Send the file, customer name, and customerID to the API
      const response = await fetch(`${apiUrl}/api/send/appreciation`, {
        method: "POST",
        body: formData,
      });

      // Check response status and handle accordingly
      if (!response.ok) {
        throw new Error("Failed to send the file, customer name, and customer ID");
      }

      const result = await response.json();

      // Output response message from backend
      const backendMessage = result.message;

      // Toast based on backend response
      switch (backendMessage) {
        case "Already checked out":
          toast.error("Already checked out!");
          break;
        case "Guest Checkedout Successfully":
          toast.success("Guest checked out successfully!");
          break;
        default:
          toast.error("An internal error occurred!");
          break;
      }
    } catch (error) {
      console.error("Error generating or sending the PDF:", error);
      toast.error("An error occurred while processing your request.");
    } finally {
      setLoading(false); // End loading
    }
  }
};







  
  
  
  
  

  return (
  <div>
    {isLoading ? (
      <div className="Spinner-container">
        <CgSpinner size={60} className="Spinner" />
      </div>
    ) : error ? (
      <div className="Error-message">
        <p>{error}</p>
      </div>
    ) : (
      <div>
        {/* Print Button */}
        
        <button
         onClick={handleSend}
         style={{
          float: "right",
          margin: "10px",
          color: "red",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", // Center the content inside the button
          position: "relative",
          width: "150px", // Set button width as needed
          height: "50px", // Set button height as needed
          overflow: "hidden",
         }}
        >
      
         {loading && <div className="spinner"></div>}
         {loading ? "Checking Out..." : "Check Out"}
        </button>
        

        <div className="Receipt" ref={receiptRef}>
          <header className="Receipt-header">
            <div className="Receipt-container">
              <img src={logo} alt="Company Logo" />
              <div className="Receipt-head-container">
                <h1>INVOICE</h1>
              </div>
            </div>
          </header>

          <section className="Receipt-address">
            <div className="Receipt-address-container">
              <div className="Receipt-address-container-left">
                <p>Lobi Quarters, Makurdi Benue State.</p>
                <p>+2347055443322</p>
                <p>inquiries@ropajo.com</p>
                <p>www.ropajo.com</p>
              </div>
              <div className="Receipt-address-container-right">
                <p>Bill Collected By {staffName || "Staff"}</p>
                <h2>{guest?.fullName || "Guest Name"}</h2>
                <div className="Receipt-address-container-right-profile">
                  <p>{guest?.Nationality || "Unknown"}</p>
                  <p>{guest?.address || "No Address Provided"}</p>
                  <p>{guest?.email || "No Email Provided"}</p>
                </div>
              </div>
            </div>
          </section>

          <div className="Receipt-body">
            <section className="Receipt-table">
              <div className="Receipt-table-head">
                <p>DATE</p>
                <p>DESCRIPTION</p>
                <p>PRICE</p>
                <p>PAID</p>
                <p>BALANCE</p>
              </div>

              <div className="Receipt-table-body">
                {/* Rooms */}
                {(roomData?.length > 0 ? roomData : []).map((room) => {
                  const matchingPayment = paymentData?.find(
                    (payment) =>
                      payment.category === "Room" &&
                      payment.comment.includes(`id:${room._id}`)
                  );

                  const firstBalance =
                    (matchingPayment?.bill || 0) - (matchingPayment?.paid || 0);

                  return (
                    <div key={room._id} className="Receipt-table-row">
                      <p>
                        <DateFormatter
                          isoDate={room.assignedDate || "N/A"}
                        />
                      </p>
                      
                      <p>{room.name || "N/A"}
                      
                      {matchingPayment?.duration ? (
                           <span style={{ marginLeft: "8px", color: "lightbrown" }}>
                       {matchingPayment?.duration} nights
                      </span>
                       ) : null}
                       
                      </p>
                      
                      <p>{matchingPayment?.bill?.toLocaleString() || "0.0"}</p>
                      <p>{matchingPayment?.paid?.toLocaleString() || "0.0"}</p>
                      <p>{firstBalance.toLocaleString() || "0.0"}</p>
                    </div>
                  );
                })}

                {/* Other Categories */}
                {paymentData
                  ?.filter((payment) => payment.category !== "Room")
                  .map((payment) => {
                    const categoryBalance =
                      (payment.bill || 0) - (payment.paid || 0);
                    return (
                      <div key={payment._id} className="Receipt-table-row">
                        <p>
                          <DateFormatter
                            isoDate={payment.assignedDate || "N/A"}
                          />
                        </p>
                        <p>{payment.comment || "N/A"}</p>
                        <p>{payment.bill?.toLocaleString() || "0.0"}</p>
                        <p>{payment.paid?.toLocaleString() || "0.0"}</p>
                        <p>{categoryBalance.toLocaleString() || "0.0"}</p>
                      </div>
                    );
                  })}
              </div>
            </section>
          </div>

          <footer className="Receipt-footer">
            <section className="Receipt-footer-left">
              <div className="Receipt-policy">
                <h2 className="Receipt-policy-header">
                  RETURN POLICY, TERMS AND CONDITIONS
                </h2>
                <p className="Receipt-policy-text">
                  The content of your return policy, terms, and conditions can
                  be inserted here. Make sure it covers all the necessary
                  information for the customer.
                </p>
              </div>
              <div className="Receipt-bank-detail">
                <p>ROPAJO HOTELS AND APARTMENTS</p>
                <p>0099887766</p>
                <p>ZENITH BANK PLC</p>
              </div>
            </section>

            <section className="Receipt-footer-right">
              <div className="Receipt-acc">
                <div className="Receipt-acc-board">
                  <div className="Receipt-acc-title">
                    <p>DEPOSIT</p>
                    <p>BALANCE</p>
                    <p>TOTAL</p>
                  </div>
                  <div className="Receipt-acc-amount">
                    <p>
                      {[
                        ...(roomData?.map((room) => {
                          const matchingPayment = paymentData?.find(
                            (payment) =>
                              payment.category === "Room" &&
                              payment.comment.includes(`id:${room._id}`)
                          );
                          return matchingPayment?.paid || 0;
                        }) || []),
                        ...(paymentData?.filter(
                          (payment) => payment.category !== "Room"
                        ).map((payment) => payment.paid || 0) || []),
                      ]
                        .reduce((acc, curr) => acc + curr, 0)
                        .toLocaleString()}
                    </p>
                    <p>
                      {[
                        ...(roomData?.map((room) => {
                          const matchingPayment = paymentData?.find(
                            (payment) =>
                              payment.category === "Room" &&
                              payment.comment.includes(`id:${room._id}`)
                          );
                          return (
                            (matchingPayment?.bill || 0) -
                            (matchingPayment?.paid || 0)
                          );
                        }) || []),
                        ...(paymentData?.filter(
                          (payment) => payment.category !== "Room"
                        ).map(
                          (payment) =>
                            (payment.bill || 0) - (payment.paid || 0)
                        ) || []),
                      ]
                        .reduce((acc, curr) => acc + curr, 0)
                        .toLocaleString()}
                    </p>
                    <p>
                      {(
                        [
                          ...(roomData?.map((room) => {
                            const matchingPayment = paymentData?.find(
                              (payment) =>
                                payment.category === "Room" &&
                                payment.comment.includes(`id:${room._id}`)
                            );
                            return matchingPayment?.paid || 0;
                          }) || []),
                          ...(paymentData?.filter(
                            (payment) => payment.category !== "Room"
                          ).map((payment) => payment.paid || 0) || []),
                        ].reduce((acc, curr) => acc + curr, 0) +
                        [
                          ...(roomData?.map((room) => {
                            const matchingPayment = paymentData?.find(
                              (payment) =>
                                payment.category === "Room" &&
                                payment.comment.includes(`id:${room._id}`)
                            );
                            return (
                              (matchingPayment?.bill || 0) -
                              (matchingPayment?.paid || 0)
                            );
                          }) || []),
                          ...(paymentData?.filter(
                            (payment) => payment.category !== "Room"
                          ).map(
                            (payment) =>
                              (payment.bill || 0) - (payment.paid || 0)
                          ) || []),
                        ].reduce((acc, curr) => acc + curr, 0)
                      ).toLocaleString()}
                    </p>
                  </div>
                </div>
              </div>
              <div className="Receipt-hat-container">
                <img src={hat} alt="Logo Hat" />
              </div>
            </section>
          </footer>
        </div>
      </div>
    )}
  </div>
);




};

export default Receipt;
