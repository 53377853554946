/** @format */

import React, { useContext, useState } from "react";

import { CgSpinner } from "react-icons/cg";
import "./GuestId.css";
import guest_img from "../../assets/Ropa2.jpeg";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";

import { Store } from "../../Store";




const GuestId = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // const { state, dispatch } = useContext(Store);
  const { dispatch } = useContext(Store);
  // const { userInfo } = state;
  const [userUniqueId, setUserUniqueId] = React.useState("");
  const [isLoading, setIsLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;


   /*
   Fetch user session from the server using cookies on initial load
   React.useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/auth/session`, {
          method: "GET", // Using GET method to retrieve data without sending a body
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include", // Ensures cookies are sent with the request
        });
      
        if (response.status === 200) {
          dispatch({ type: "USER_SIGNIN", payload: response.data });
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    if (!userInfo) {
      fetchUserSession();
    }
  }, [apiUrl, userInfo, dispatch]);

  React.useEffect(() => {
    if (userInfo) {
      const query = new URLSearchParams(location.search);
      const redirect = query.get("redirect");
      navigate(redirect || "/GuestAccessPage");
    }
  }, [navigate, userInfo, location.search]);

  */
  
  
  
  
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure Guest ID is provided
    if (!userUniqueId.trim()) {
      toast.error("Guest ID cannot be empty");
      return;
    }

    setIsLoading(true); // Set loading state to true
   
   
    try {
      const response = await fetch(`${apiUrl}/api/guest/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
        body: JSON.stringify({ userUniqueId: userUniqueId.trim() }),
      });
      
      console.log("Response status:", response.status);
      console.log("Response headers:", response.headers);
    
      if (!response.ok) {
        let errorMessage = `HTTP Error: ${response.status}`;

      // Attempt to parse the response JSON if available
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        console.warn("Failed to parse error response:", parseError);
      }

      throw new Error(errorMessage);
    }
      
      const data = await response.json();
      
      if (data && data._id) {
          
        localStorage.setItem('userInfo', JSON.stringify(data));

        // Retrieve staffInfo from localStorage
        const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        
        
        toast.success("Login successful");

        // Dispatch user data to global state
        // const userData = { _id: data._id };
        dispatch({ type: "USER_SIGNIN", payload: storedUserInfo });

        // Handle redirect
        const query = new URLSearchParams(location.search);
        const redirect = query.get("redirect");
        navigate(redirect || "/GuestAccessPage");
        }
    } catch (err) {
      console.error("Login failed:", {
        message: err.message,
        stack: err.stack,
        originalError: err,
    });
    
      // Display the error message
      const errorMessage = err.message || "An error occurred";
      toast.error(errorMessage);
    } finally {
      setIsLoading(false); // Reset loading state after request completes
      }
    };






  return (
    <div className="guest-id">
      <div className="guest-img-holder">
        <img src={guest_img} alt="Guest" />
      </div>
      <div className="guest-des-holder">
        <form onSubmit={handleSubmit} className="guest-form">
          <fieldset>
            <legend>Enter your unique Guest ID</legend>
            <input
              value={userUniqueId}
              onChange={(e) => setUserUniqueId(e.target.value)}
              type="text"
              placeholder="#R1410X"
            />
          </fieldset>

          <button type="submit" disabled={isLoading}>
            {isLoading ? (

              <CgSpinner className="animate-spin mr-2" size={20} />
            ) : (
              "Enter Guest ID"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default GuestId;
