import React, { useState } from "react";
import "./CashierManageGuest.css";
import CashierManageGuestList from "./CashierManageGuestList/CashierManageGuestList";


import ReceptionistDashboard from "../CashierProfile/ReceptionistDashboard";
import ReceptionHeader from "../CashierHeader/ReceptionHeader";


const CashierManageGuestItems = [
  {
    Name: "Peter Patrick F.",
    Id: "R1410x9232df7",
    Position: "Receptionist",
  },
  {
    Name: "Alice Matthew",
    Id: "R1410x9232df7",
    Position: "Bartender",
  },
  {
    Name: "Moses Terna",
    Id: "R1410x9232df7",
    Position: "Waitress",
  },
  {
    Name: "Hamsizan Katrinna",
    Id: "R1410x9232df7",
    Position: "Manager",
  },
  {
    Name: "Fanen Torkura JP",
    Id: "R1410x9232df7",
    Position: "Security",
  },
  {
    Name: "John Terhemba",
    Id: "R1410x9232df7",
    Position: "Tecnical",
  },
  {
    Name: "Fanen Torkura JP",
    Id: "R1410x9232df7",
    Position: "Gardener",
  },
  {
    Name: "Hamsizan Katrinna Malvin",
    Id: "R1410x9232df7",
    Position: "Cleaner",
  },
  {
    Name: "Peter Patrick F.",
    Id: "R1410x9232df7",
    Position: "Receptionist",
  },
  {
    Name: "Alice Matthew",
    Id: "R1410x9232df7",
    Position: "Receptionist",
  },
  {
    Name: "Hamsizan Katrinna Malvin",
    Id: "R1410x9232df7",
    Position: "Cleaner",
  },
];

const CashierManageGuest = () => {
    
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);



  return (
    <div className="CashierManageGuest">
    
    
      <div className="CashierManageGuest-profile">
      <ReceptionistDashboard receptionProfileShow={true} />
      </div>
      
      
      <div className="CashierManageGuest-space">
      <div className="CashierDashboard-mobile-header">
          <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
        </div>
        
        
        <div className="CashierManageGuest-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="CashierManageGuest-space-body">
          <div className="CashierManageGuest-title-board">
            <p>Guest Information</p>

            <input type="text" placeholder="Search Guests" />
          </div>
          <section className="CashierManageGuest-board">
            <div className="CashierManageGuest-center-board">
              <div className="CashierManageGuest-table-head">
                <p>Name</p>
                <p>Unique No.</p>
                <p> Contact</p>
              </div>
              <div className="CashierManageGuest-table-body">
                {CashierManageGuestItems.map((items, index) => (
                  <CashierManageGuestList
                    Name={items.Name}
                    Id={items.Id}
                    Position={items.Position}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CashierManageGuest;
