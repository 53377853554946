import React from "react";
import "./ReservedRoomBtn.css";
import { Link } from "react-router-dom";
const ReservedRoomBtn = ({ id, amount, index, room, guest, roomId, date }) => {
  return (
      
    <Link
      to={`/ReservationInfo/:${guest}`}
      onClick={() =>
          localStorage.setItem(
          "guestDashboardProps",
          JSON.stringify({ amount, date, room, roomId, id, index, guest})
        )
        }
    >
      <button key={index} className="ReservedRoomBtn">
        <div>{amount}</div> <br />
        <span>{room}</span>
      </button>
    </Link>
    
    
  );
};

export default ReservedRoomBtn;
