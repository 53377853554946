/** @format */

import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";

/*import AvailableRooms from "../Staff-Receptionist/AvailableRooms/AvailableRooms";
*/


import ReceptionHeader from "../ReceptionHeader/ReceptionHeader";
import ReceptionistDashboard from "../ReceptionistDashboard/ReceptionistDashboard";


import ReservedRoomBtn from "../ReservedRoomBtn/ReservedRoomBtn";


import "./Reservations.css";
import toast from "react-hot-toast";
import { Store } from "../../../../Store";
import { useNavigate } from "react-router-dom";



const Reservations = () => {
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;

  // State variables
  const [rooms, setRooms] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bookings, setBookings] = useState(null);
  
  const navigate = useNavigate();
  const { dispatch } = useContext(Store);
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminStaffModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/SignIn");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          const storedStaffInfo = JSON.parse(localStorage.getItem('staffInfo'));
          
          
          if (storedStaffInfo && storedStaffInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "STAFF_SIGNIN", payload: storedStaffInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/Reservations", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/Reservations");
          } else {
            navigate("/SignIn");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/SignIn");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  // Fetch rooms
  useEffect(() => {
    const fetchRooms = async () => {
      setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(`${apiUrl}/api/rooms/view-room`);

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch rooms");
        }

        const data = await response.json();
        setRooms(data.data);
        toast.success("Rooms fetched successfully!");
      } catch (err) {
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchRooms();
  }, [apiUrl]); // Dependency added to avoid refetch on every render

  // Fetch bookings
  useEffect(() => {
    const fetchBookings = async () => {
      setLoading(true);
      setError(null); // Reset error state
      try {
        const response = await fetch(`${apiUrl}/api/bookings/view-booking`);
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch bookings");
        }

        const data = await response.json();
        const numberBookings = data.data.length;
        setBookings(numberBookings);
        toast.success("Bookings fetched successfully!");
      } catch (err) {
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBookings();
  }, [apiUrl]); // Dependency added to avoid refetch on every render
  
  
  
  useEffect(() => {
  if (error) {
    const timer = setTimeout(() => {
      setError(null); // Clear the error after 5 seconds
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on unmount
  }
}, [error]);
  
  
  
  
  // Filter available rooms
  const reservation = rooms?.filter((room) => room.type === "Reservation") || [];
  

  return (
    <div className="StaffDashboard">
      <div className="StaffDashboard-web">
        <div className="StaffDashboard-guest-section">
          <ReceptionistDashboard receptionProfileShow={true} />
        </div>

        <div className="StaffDashboard-content-section">
          <div className="StaffDashboard-mobile-header">
            <ReceptionistDashboard
              receptionProfileShow={receptionProfileShow}
              setReceptionProfileShow={setReceptionProfileShow}
            />
            <ReceptionHeader
              setReceptionProfileShow={setReceptionProfileShow}
            />
          </div>

          <div className="StaffDashboard-content-head">
            <Link>Back to Homepage</Link>
          </div>

          <div className="StaffDashboard-content-body">
            <p>Reserved 🌄 Rooms</p>



            <div className="Staff-content-table-section">
              {error && <p className="error-message">Error: {error}</p>}




              <div className="staff-room-btn-section">
              
              
                {loading ? (
                      <div className="loader"></div>
                    ) : reservation.length ? (
                    <div className="room-grid">
                      {reservation.map((room, index) => (
                        <ReservedRoomBtn
                          id={room._id}
                          amount={`N${room.price.toLocaleString()}`}
                          room={room.name}
                          index={index}
                          date={room.assignedDate}
                          guest={room.collectedBy}
                          roomId={room._id}
                        />
                      ))}
                    </div>
                    ) : (
                      <p>No reserved rooms.</p>
                    )}
                    
                    
              </div>
             
             
             </div>
             
             
             
             <div className="reserve-option-btn-section">
            
            
            
            
            <button
                className="reserve-dashboard-button-navigate"
                onClick={() => navigate("/StaffDashboard")}
              >
                <span>Available Rooms</span>
          </button>
            
            
           
          
          
          </div>
             
             
             
             
             
             
          </div>

              
            
         
          
          
          
          

          <div className="staff-option-btn-section">
            <Link to="/RegisterGuests" className="staff-dashboard-button">
              Register Guest
            </Link>

            <Link to="/OnlineBookingList" className="staff-dashboard-button-bookings">
              <span className="button-text">Bookings</span>
              <span className="notification">
                {loading ? "!" : bookings > 0 ? bookings : "0"}
              </span>
            </Link>

            <Link to="/ManageGuest" className="staff-dashboard-button">
              Manage Guests
            </Link>
          </div>
          
          
        </div>
      </div>
    </div>
  );
};

export default Reservations;

