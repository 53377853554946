/** @format */

import React, { useContext, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { Store } from "../../../Store";
import { convertToBase64 } from "../../../utils/convertImg";
import { generateUniqueNumbers } from "../../../utils/generateId";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import "./CreateStaff.css";
import { CgSpinner } from "react-icons/cg";

const CreateStaff = () => {

  const navigate = useNavigate();
   
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [idPhoto, setIdPhoto] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [photo, setPhoto] = useState("");
  const [address, setAddress] = useState("");
  const [position, setPosition] = useState("");
  const [gender, setGender] = useState("");
  const [salary, setSalary] = useState("");
  const [qualification, setQualification] = useState("");
  const unique = generateUniqueNumbers(1, 6)[0];
  const staffUniqueId = `RPJ${unique}`;

  const [isLoading, setIsLoading] = useState(false);
  const [fileName, setFileName] = useState(""); // State for file name
  const [idFileName, setIdFileName] = useState(""); 
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  
  const {
    state: { adminInfo },
    dispatch
  } = useContext(Store);
  
  
  console.log(adminInfo);
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  React.useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/AdminLogin");
          toast.error("Authorization Failed");
          console.log("Session expired, please log in again.");
          return;
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          dispatch({ type: "ADMIN_SIGNIN", payload: data });
          // Set profileShow based on token availability
          
          
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/AdminDashboard", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);

          // Navigate to the validated redirect or default to /AdminDashboard
          navigate(isValidRedirect ? redirect : "/CreateStaff");
         
        } else {
            
          navigate("/AdminLogin");
          // Log all other status codes
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
          
          return;
        }
      } catch (error) {
        navigate("/AdminLogin");
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [dispatch, navigate, apiUrl]);
  
 
  const validateFileSize = (file) => {
    const maxSize = 15 * 1024 * 1024; // 15 MB in bytes
    return file.size <= maxSize;
  };
  

 
  
  const uploadIdPhoto = async (e) => {
    const file = e.target.files[0];
    if (file && validateFileSize(file)) {
      setIdFileName(file.name); // Set the file name
      try {
        const base64 = await convertToBase64(file);
        setIdPhoto(base64); // Set the base64 string
      } catch (error) {
        console.error("Error compressing image:", error);
      }
    } else {
      setIdFileName('');
      toast.error("ID photo size must not exceed 16MB.");
  }
};

  
  
  const uploadPhoto = async (e) => {
    const file = e.target.files[0];
    if (file && validateFileSize(file)) {
      setFileName(file.name); // Set the file name
      try {
        const base64 = await convertToBase64(file);
        setPhoto(base64); // Set the base64 string
      } catch (error) {
        console.error("Error compressing image:", error);
      }
  } else {
    setFileName('');
    toast.error("Photo size must not exceed 16MB.");
  }
};
 
  const validateInputs = () => {
    if (!fullName || !email || !phoneNumber || !address || !photo || !position || !gender) {
      toast.error("All fields are required.");
      return false;
    }

    // Validate email format
    const emailPattern = /^[^@]+@[^@]+\.[^@]+$/;
    if (!emailPattern.test(email)) {
      toast.error("Invalid email format.");
      return false;
    }

    // Validate phone number (example: should be numeric and of appropriate length)
    const phonePattern = /^\+?[0-9]{1,4}?[-.s]?[0-9]{1,4}?[-.s]?[0-9]{3,10}$/;
    if (!phonePattern.test(phoneNumber)) {
      toast.error("Invalid phone number format.");
      return false;
    }
    return true;
  };
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateInputs()) {
      toast.error("Please make sure all inputs are correctly inputed!");
      return;
    }
    
    setIsLoading(true); // Set loading state

    try {
      const response = await fetch(`${apiUrl}/api/admin-staff/add-staff`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensure cookies are sent with the request
        body: JSON.stringify({
          fullName,
          email,
          position,
          address,
          qualification,
          phoneNumber,
          dateOfBirth,
          gender,
          salary,
          photo,
          idPhoto,
          staffUniqueId,
          active: true,
        }), // Include the necessary data in the request body
      });

      if (!response.ok) {
        // Check for HTTP errors
        const errorData = await response.json();
        const errorMessage = errorData.message || 'An error occurred';
        throw new Error(errorMessage);
      }

      // Handle successful response
      // const data = await response.json();
      toast.success("staff added succefully");
      navigate("/StaffList");

    } catch (error) {
      // Handle both frontend and backend errors
      if (error.response && error.response.data) {
        // If the error contains a specific response and data
        toast.error(`Staff Creation Failed: ${error.response.data.message}`);
      } else {
        toast.error(`Staff Creation Failed: ${error.message}`);
    }
    } finally {
    setIsLoading(false); // Reset loading state
    }
    };
  
  
  
  
  return (
    <div className="CreateStaff">
    
    {isLoading && (
        <div className='loading-overlay'>
          <CgSpinner className="animate-spin" size={40} />
        </div>
      )}
    
      <div className="CreateStaff-hue">
        <div>
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>

        <div className="CreateStaff-body">
          <form onSubmit={handleSubmit} className="CreateStaff-form">
            <h1>Create Staff</h1>
            <label htmlFor="">
              Name:
              <input
                type="text"
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                id=""
                placeholder=""
              />
            </label>
            <label htmlFor="">
              Address:
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                id=""
                placeholder=""
              />
            </label>
            <label htmlFor="">
              Email:
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id=""
                placeholder=""
              />
            </label>
            <label htmlFor="">
              Contact:
              <input
                type="number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                id=""
                placeholder=""
              />
            </label>

            <section className="seprate" htmlFor="">
              <label htmlFor="">
                D.O.B:
                <input
                  type="text"
                  value={dateOfBirth}
                  onChange={(e) => setDateOfBirth(e.target.value)}
                  name=""
                  id=""
                  placeholder=""
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                />
              </label>
              
              
              <label htmlFor="">
                Gender:
                <select
                  name="Gender"
                  value={gender}
                  id="Gender"
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled>Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
              </label>
              
              
            </section>
            <label htmlFor="">
              {/* >>>>>>> Stashed changes */}
              Qualification:
              <input
                type="text"
                value={qualification}
                onChange={(e) => setQualification(e.target.value)}
                id=""
                placeholder=""
              />
            </label>

            <section htmlFor="" className="seprate">
            
            
              <label htmlFor="">
                Position:
                <select
                  value={position}
                  name=""
                  id=""
                  onChange={(e) => setPosition(e.target.value)}
                >
                  <option value="" disabled>Select Position</option>
                  <option value="Manager">Manager</option>
                  <option value="Bartender">Bartender</option>
                  <option value="Receptionist">Receptionist</option>
                  <option value="Waiter">Waiter</option>
                  <option value="Cashier">Cashier</option>
                  <option value="House keeper">House keeper</option>
                  <option value="Security">Security</option>
                  <option value="Technician">Technician</option>
                  <option value="Cook">Cook</option>
                  <option value="Others">Others</option>
                </select>
              </label>
              
              

              <label htmlFor="">
                Salary:
                <input
                  type="text"
                  value={salary}
                  onChange={(e) => setSalary(e.target.value)}
                  name=""
                  id=""
                  placeholder=""
                />
              </label>
            </section>

            <label className="seprate">

              <span>Upload Photo:</span>
              
              {fileName && <p className="truncate">{fileName}</p>}
              

              <input
                type="file"
                onChange={(e) => uploadPhoto(e)}
                id="upload-photo"
                placeholder="Upload Photo:"
                hidden
              />
              <span htmlFor="upload-photo" className="CreateStaff-file-label">
                upload
              </span>
            </label>

            
            <label className="seprate">
              <span>Upload ID:</span>
              
              {idFileName && <p className="truncate">{idFileName}</p>}
              

              <input
                type="file"
                onChange={(e) => uploadIdPhoto(e)}
                id="upload-id"
                placeholder="Upload ID:"
                hidden
              />
              <span htmlFor="upload-id" className="CreateStaff-file-label">
                upload
              </span>
            </label>

            
              <button
                style={{ cursor: "pointer" }}
                type="submit"
                className="CreateStaff-submit"

                disabled={isLoading}
              >
                {isLoading ? (
                <CgSpinner className="animate-spin mr-2" size={20} />
              ) : (
                "Submit"
              )}
              </button>
           

          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateStaff;
