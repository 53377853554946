
import "./StaffList.css";
// import ReceptioistManageProfile from "../../ReceptioistManageProfile/ReceptioistManageProfile";
import React, { useState, useContext } from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import AdminProfile from "../AdminProfile/AdminProfile";
import StaffListItem from "./StaffListItem/StaffListItem";
import { Store } from "../../../Store";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";


const StaffList = () => {

  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  
  const navigate = useNavigate();
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  const { state: { adminInfo }, dispatch } = useContext(Store);
  
  const [staffs, setStaffs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  React.useEffect(() => {
  
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/AdminLogin");
          toast.error("Authorization Failed");
          console.log("Session expired, please log in again.");
          return;
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          dispatch({ type: "ADMIN_SIGNIN", payload: data });
          // Set profileShow based on token availability
          
          
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/StaffList", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);
          
          // Navigate to the validated redirect or default to /AdminDashboard
          navigate(isValidRedirect ? redirect : "/StaffList");
         
        } else {
            
          navigate("/AdminLogin");
          // Log all other status codes
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
          
          return;
        }
      } catch (error) {
        navigate("/AdminLogin");
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [dispatch, navigate, apiUrl]);
  
  
  
  
  
 

  React.useEffect(() => {
    const fetchStaffs = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const response = await fetch(`${apiUrl}/api/admin-staff/all-staffs`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Ensures cookies are sent with the request
      });
       
       
      
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch staffs.");
        }
       
       
        const data = await response.json();
        setStaffs(data.staffs);
        toast.success("Staff data loaded successfully!");
      } catch (err) {
        console.error("Fetch error:", err.message); // Log error for debugging
        setError(err.message);
        toast.error(`Error: ${err.message}`);
      } finally {
        setIsLoading(false);
      }
    };
    
    if(adminInfo){
      fetchStaffs();
    }
  }, [apiUrl, adminInfo]);

 
  
  
  
  
  
  

  return (
    <div className="StaffList">
      <div className="StaffList-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="StaffList-space">
        <div className="StaffList-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <div className="StaffList-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="StaffList-space-body">
          <div className="StaffList-title-board">
            <h2>Staff List</h2>
            <input type="text" placeholder="Search Staff" />
          </div>
          <section className="StaffList-board">
            <div className="StaffList-center-board">
              <div className="StaffList-table-head">
                <p>Name</p>
                <p>Unique No.</p>
                <p>Position</p>
              </div>

              
              
              
              <div className="StaffList-table-body">
                {isLoading ? (
                   <p style={{ color: "black" }}>Loading...</p>
                ) : error ? (
                   <p style={{ color: "red" }}>{error}</p>
                ) : (
                   staffs?.map((items, index) => (
                     <StaffListItem
                        name={items.fullName}
                        id={items.staffUniqueId}
                        position={items.position}
                        idx={items._id}
                        index={index}
                        key={index}
                      />
                    ))
                )}
              </div>

              
              

            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default StaffList;
