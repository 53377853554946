import React, { useContext, useEffect, useState } from "react";
import "./GuestAccessPage.css";


import { Link, useNavigate } from "react-router-dom";

import ReceptionistDashboard from "../GuestDashBoard/ReceptionistDashboard";
import ReceptionHeader from "../GuestHeader/ReceptionHeader";
// import DashboardRoomProfile from "../../DashboardRoomProfile/DashboardRoomProfile";
import { Store } from "../../../Store";

import toast from "react-hot-toast";








const GuestAccessPage = () => {

  
  
  const navigate = useNavigate();
  
  const [receptionProfileShow, setReceptionProfileShow] = useState(false);
  
  const { dispatch } = useContext(Store);
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  const [isLoading, setIsLoading] = useState(false);
  const [wifiCredentials, setWifiCredentials] = useState(null);
  const savedProps = JSON.parse(localStorage.getItem("userInfo")) || {};
  const name = savedProps.fullName || "N/A";
  const id = savedProps.userUniqueId || "N/A";
  const phone = savedProps.phoneNumber || "N/A";
        
  const [error, setError] = useState(null);
  
  
  
  
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  

  useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "UserModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/");
          console.log("Session expired, please log in again.");
          toast.error("Authorization Failed");
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          // setProfileShow(true); // If a valid token exists, show the profile
          localStorage.setItem('userInfo', JSON.stringify(data));
          
          const storedUserInfo = JSON.parse(localStorage.getItem('userInfo'));
          
          
          if (storedUserInfo && storedUserInfo.userUniqueId === data.userUniqueId) {

            // IDs match, proceed with the signin process
            dispatch({ type: "USER_SIGNIN", payload: storedUserInfo });
        
            // Set profileShow based on token availability
            // setProfileShow(true); 
        
            // Get redirect from secure cookie
            const redirect = getCookie("redirect");

            // Clear the redirect cookie after use
            document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

            // Validate redirect path to prevent open redirect vulnerabilities
            const validRedirects = ["/GuestAccessPage", "/settings", "/profile"];
            const isValidRedirect = redirect && validRedirects.includes(redirect);

            // Navigate to the validated redirect or default to /AdminDashboard
            navigate(isValidRedirect ? redirect : "/GuestAccessPage");
          } else {
            navigate("/");
            // If IDs do not match, handle the mismatch (e.g., show an error message)
            console.error("Error: User ID mismatch");
            toast.error("Error: User ID mismatch");
            return;
          }
        } else {
          // Log all other status codes
          navigate("/");
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [apiUrl, navigate, dispatch]);
  
  
  
  
  const fetchWifiCredentials = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiUrl}/api/manage-guest/access/get-wifi-credentials`);
      if (!response.ok) throw new Error("Failed to fetch credentials.");
      const data = await response.json();
      setWifiCredentials(data.data);
      setTimeout(() => setWifiCredentials(null), 2 * 60 * 60 * 1000); // 2 hours
    } catch (err) {
      setError(err.message);
      console.log(error)
      toast.error(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  







  return (
    <div className="GuestAccessPage-web" >
    
      <div className="GuestAccessPage-guest-section">
        <ReceptionistDashboard receptionProfileShow={true} />

      </div>

      <div className="GuestAccessPage-content-section">
        <div className="GuestAccessPage-content-head">
          <Link to="/">Back to Homepage</Link>
        </div>

        
        
        <div className="GuestAccessPage-mobile-header">
          <ReceptionistDashboard
            receptionProfileShow={receptionProfileShow}
            setReceptionProfileShow={setReceptionProfileShow}
          />
          <ReceptionHeader setReceptionProfileShow={setReceptionProfileShow} />
        </div>
        
        
        

        <div className="GuestAccessPage-content-body">
          <p>{/*Account*/} </p>
          <div>
            <div className="GuestAccessPage-content-table-section">
              {/* table */}
              <div className="access-card">
                <div className="access-card-box">

                  <h2>{name || " "}</h2>
                  <p>Unique Number: {id || ""}</p>
                  <p>Phone Number: {phone || ""}</p>
                </div>
                <div className="access-card-box double-box">
                
                
                  <div>
                    <h2>WIFI</h2>
                    <div>
                    
                      {isLoading ? (
                      <p>Loading...</p>
                    ) : wifiCredentials ? (
                      <>
                        <p>Username: {wifiCredentials.ssid}</p>
                        <p>Password: {wifiCredentials.password}</p>
                      </>
                    ) : (
                      <button onClick={fetchWifiCredentials}>Get WiFi Credentials</button>
                    )}
                    
                    </div>
                  </div>
                  
                  
                  
                  <div>
                    <h2>Contacts</h2>
                    <div>
                      <p>Receptionist: 08159542100</p>
                      <p>Bar: 08159542101</p>
                      <p>Waiter: 08159542102</p>
                      <p>Restaurant: 08159542103</p>

                    </div>
                  </div>
                </div>
                <div className="access-card-box access-contact-box">
                  <h2>Emergency</h2>
                  <p>08159542159</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestAccessPage;
