
import axios from "axios";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Base URL from environment variable
  withCredentials: true,

  headers: {
    "Content-Type": "application/json",
  },
});


// Interceptor to modify request configuration if needed
apiClient.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error) // Handle request errors

);

export default apiClient;
