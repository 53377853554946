import React from "react";
import "./CashierIncomingTableRow.css";

const CashierIncomingTableRow = ({ color, date, category, collectedBy, bill, received, receivedDate, balance, receivedBy }) => {
  return (
    <div>
      <div className="ReceptionAccountTableRow">
        <span style={{ color: color }}>{date}</span>
        <span style={{ color: color }}>{category}</span>
        <span style={{ color: color }}>{collectedBy}</span>
        <span style={{ color: color }}>{bill}</span>
        <span style={{ color: color }}>{received}</span>
        <span style={{ color: color }}>{receivedDate}</span>
        <span style={{ color: color }}>{balance}</span>
        <span style={{ color: color }}>{receivedBy}</span>
      </div>
      <figure className="ReceptionAccountTableRow-mob">
        <div className="ReceptionAccountTableRow-mob-header">
          <p style={{ color: color }}>{category}</p>
          <p style={{ color: color }}>Date: {date}</p>
        </div>
        <div className="ReceptionAccountTableRow-mod-body">
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Collected By:</p>
            <p style={{ color: color }}>{collectedBy}</p>
          </div>
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Bill:</p>
            <p style={{ color: color }}>{bill}</p>
          </div>
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Recieved:</p>
            <p style={{ color: color }}>{received}</p>
          </div>
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Recieved Date:</p>
            <p style={{ color: color }}>{receivedDate}</p>
          </div>
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Balance:</p>
            <p style={{ color: color }}>{balance}</p>
          </div>
          <div className="ReceptionAccountTableRow-mob-cont">
            <p style={{ color: color }}>Last Received By:</p>
            <p style={{ color: color }}>{receivedBy}</p>
          </div>
        </div>
      </figure>
    </div>
  );
};

export default CashierIncomingTableRow;
