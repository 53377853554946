import { formatDistanceToNow } from "date-fns";
import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { useGetExpensesQuery } from "../../../hooks/payment";
import { useGetStaffsQuery } from "../../../hooks/staff";
import { format } from "date-fns"; // For date formatting

import AdminProfile from "../AdminProfile/AdminProfile";
import AdminHeader from "../AdminHeader/AdminHeader";

import AdminExpenseList from "./AdminAccountList/AdminExpense";
import "./AdminAccounts.css";




const CExpenses = () => {
  const { data: AdminAccountitems, isPending: isFetchingPayments } =
    useGetExpensesQuery();
  const { data: staff, isPending: isFetchingStaffs } = useGetStaffsQuery();
  
  
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  const [categoryFilter, setCategoryFilter] = useState(""); // State for category filter
  const [selectedDate, setSelectedDate] = useState(""); // State for specific date filter

  const handleResetFilters = () => {
    setCategoryFilter("");
    setSelectedDate("");
  };

  const filteredItems = AdminAccountitems?.filter((item) => {
    const matchesCategory = categoryFilter
      ? item.category.toLowerCase().includes(categoryFilter.toLowerCase())
      : true;

    const itemDate = format(new Date(item.createdAt), "yyyy-MM-dd"); // Format item date
    const matchesDate = selectedDate ? itemDate === selectedDate : true;

    return matchesCategory && matchesDate;
  });
  // Calculate totals
  const totalAmount =
    filteredItems?.reduce((sum, item) => sum + item.amount, 0) || 0;

  return (
    <div className="CashierDashboard">
    
    
      <div className="CashierDashboard-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      
      
      <div className="CashierDashboard-space">
        <div className="CashierDashboard-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        
        
        <div className="AdminAccounts-space-head">
          <p>Back to Homepage</p>
        </div>
        <div className="AdminAccounts-space-body">
          <div className="AdminAccounts-title-board">
            <h2>Expenses:</h2>
            {isFetchingPayments || isFetchingStaffs ? (
              <CgSpinner
                className="animate-spin"
                style={{ color: "red" }}
                size={40}
              />
            ) : null}
            <div className="filter-controls">
              {/* Date Picker */}
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                style={{ color: "black", padding: 7 }}
              />

              <select
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}
                style={{ color: "black", padding: 7 }}
              >
                <option value="">Category</option>
                <option value="Bar">Bar</option>
                <option value="Restaurant">Restaurant</option>
                <option value="Laundry">Laundry</option>
                <option value="Room">Room</option>
                <option value="Others">Others</option>
              </select>
              <button onClick={handleResetFilters} className="reset-button">
                Reset
              </button>
            </div>
          </div>
          <section className="AdminAccounts-board">
            <div className="AdminAccounts-center-board">
              <div className="AdminAccounts-table-head">
                <p>Staff</p>
                <p>Date</p>
                <p>Category</p>
                <p>Description</p>
                <p>Amount</p>
              </div>
              <div className="AdminAccounts-table-body">
                {isFetchingPayments || isFetchingStaffs ? (
                  <span style={{ color: "black" }}>loading...</span>
                ) : filteredItems?.length > 0 ? (
                  filteredItems.map((item, index) => {
                    const staffMember = staff?.find(
                      (s) => s.staffUniqueId === item.collectedBy
                    );

                    const formattedDate = formatDistanceToNow(
                      new Date(item.createdAt),
                      {
                        addSuffix: true,
                      }
                    );

                    return (
                      <AdminExpenseList
                        key={index}
                        name={staffMember?.fullName || "Unknown"}
                        date={formattedDate}
                        category={item.category}
                        description={item.description}
                        amount={item.amount}
                        index={index}
                      />
                    );
                  })
                ) : (
                  <span style={{ color: "black" }}>No records found</span>
                )}
              </div>

              <div className="ExpenseAccounts-totals">
                <h5>TOTAL</h5>
                <h5>{parseFloat(totalAmount)}</h5>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CExpenses;
