import "./DashBoardHeader.css";

import { NavLink } from "react-router-dom";
import book_logo from "../../assets/mob-logo.svg";
import mob_menu from "../../assets/mob-menu.svg";
import { useState } from "react";


const DashBoardHeader = ({ profileShow, setViewMobileDashboard }) => {
  
  
  const [activeLink, setActiveLink] = useState("");

  // Function to handle link clicks
  const handleClick = (linkName) => {
    setActiveLink(linkName); // Set the clicked link as active
  };
  
  
  return (
    <div className="dashboard-header">
      <div className="dbr-nav">
        {/* Logo Section */}
        <div className="dbr-logo-holder">
          <NavLink to="/">
            <img src={book_logo} alt="Logo" />
          </NavLink>
        </div>

        {/* Navigation List */}
        <div className="dbr-nav-list">
          <ul>
            
            <li>
              <NavLink to="/" className={activeLink === "home" ? "active-link" : ""}
                onClick={() => handleClick("home")}
              >
                Home
              </NavLink>
            </li>
            
            
            
            <li>
              <NavLink to="/Booking" className={activeLink === "apartment" ? "active-link" : ""}
                onClick={() => handleClick("apartment")}
              >
                Apartment
              </NavLink>
            </li>
            
            <li>
              <NavLink to="/Booking" className={activeLink === "room" ? "active-link" : ""}
                onClick={() => handleClick("room")}
              >
                Room
              </NavLink>
            </li>
            
            <li>
              <NavLink to="/Booking" className={activeLink === "pool" ? "active-link" : ""}
                onClick={() => handleClick("pool")}
              >
                Pool
              </NavLink>
            </li>
            
            
            
            {profileShow ? (
              <>
                <li>
                  <NavLink to="/GuestAccount" className={activeLink === "account" ? "active-link" : ""}
                onClick={() => handleClick("account")}>Account</NavLink>
                </li>
                <li>
                  <NavLink to="/GuestSignout" className={activeLink === "signout" ? "active-link" : ""}
                onClick={() => handleClick("sign out")}>Sign Out</NavLink>
                </li>
              </>
            ) : (
              <li>
                <NavLink to="/AdminLogin" className={activeLink === "signin" ? "active-link" : ""}
                onClick={() => handleClick("sign in")}>Sign In</NavLink>
              </li>
            )}
            
            
            
            
            
            {/*
            <li>
              <NavLink to="/laundry" activeClassName="active">
                Laundry
              </NavLink>
            </li>
            <li>
              <NavLink to="/gallery" activeClassName="active">
                Gallery
              </NavLink>
            </li>
            */}
            
            
          </ul>
        </div>

        {/* Mobile Menu */}
        <img
          src={mob_menu}
          alt="Menu"
          className="dbr-mob-menu"
          onClick={() => setViewMobileDashboard(true)}
        />
      </div>
    </div>
  );
};

export default DashBoardHeader;
