
import React, { useContext, useState, useEffect } from "react";

import "./AdminDashboard.css";
import AdminProfile from "../AdminProfile/AdminProfile";
import AdminHeader from "../AdminHeader/AdminHeader";
import { Link } from "react-router-dom";

import { QRCodeCanvas } from "qrcode.react";

import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { Store } from "../../../Store";



const AdminDashboard = () => {
  const navigate = useNavigate();
   
  const [adminProfileShow, setAdminProfileShow] = useState(false);
  
  const apiUrl = process.env.REACT_APP_API_BASE_URL;
  
  const { dispatch } = useContext(Store);
  
  
  const [qrCode, setQrCode] = useState(''); 
  const [showModal, setShowModal] = useState(false);
  
  const getCookie = (name) => {
    const cookies = document.cookie.split("; ");
    const cookie = cookies.find((row) => row.startsWith(name + "="));
    return cookie ? cookie.split("=")[1] : null;
  }
  
  
  React.useEffect(() => {
    const fetchUserSession = async () => {
      try {

        const modelName = "AdminModel"; // Replace with your actual model name
        
        const response = await fetch(`${apiUrl}/api/auth/session?modelName=${modelName}`, {
          method: "GET",
          // headers: {
            // Accept: 'application/json, text/plain, */*',
          // },
          credentials: "include", // Ensures cookies are sent with the request
        });
        
        
        if (response.status === 401) {
          navigate("/AdminLogin");
          toast.error("Authorization Failed");
          console.log("Session expired, please log in again.");
          return;
        }
        
        
        if (response.status === 200) {
          const data = await response.json();
          dispatch({ type: "ADMIN_SIGNIN", payload: data });
          // Set profileShow based on token availability
          
          
          // Get redirect from secure cookie
          const redirect = getCookie("redirect");

          // Clear the redirect cookie after use
          document.cookie = "redirect=; path=/; max-age=0; secure; SameSite=Strict";

          // Validate redirect path to prevent open redirect vulnerabilities
          const validRedirects = ["/AdminDashboard", "/settings", "/profile"];
          const isValidRedirect = redirect && validRedirects.includes(redirect);

          // Navigate to the validated redirect or default to /AdminDashboard
          navigate(isValidRedirect ? redirect : "/AdminDashboard");
         
        } else {
            
          navigate("/AdminLogin");
          // Log all other status codes
          console.error(`Error: Received status code ${response.status}`);
          toast.error(`Error: ${response.status}`);
          
          return;
        }
      } catch (error) {
        navigate("/AdminLogin");
        console.error("Error fetching user session:", error);
      }
    };

    fetchUserSession();
  }, [dispatch, navigate, apiUrl]);
  
  
  
  

  const fetchQRCode = async () => {
  try {
    const response = await fetch(`${apiUrl}/api/admin-staff/qrcode/get-qr-code`);
    const data = await response.json();

    if (response.ok) {
      toast.success("QR Code fetched successfully!");
      return data.qrCode;
    } else {
      throw new Error(data.message || "Failed to fetch QR Code");
    }
  } catch (error) {
    toast.error(error.message || "An unexpected error occurred");
    console.error("Error fetching QR Code:", error);
  }
};

  

  // Fetch QR Code when Messaging button is clicked
  const handleMessagingClick = async () => {
    try {
      const qr = await fetchQRCode();
      setQrCode(qr);
      setShowModal(true);
    } catch (error) {
      console.error("Error fetching QR Code:", error);
    }
  };

  // Close modal if click is outside of it
  const handleCloseModal = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      setShowModal(false);
    }
  };
  
  
  
  
  
  useEffect(() => {
    // Clean up qrCode when modal is closed
    if (!showModal) {
      setQrCode(null);
    }
  }, [showModal]);
  
  
  
  return (
    <div className="AdminDashboard">
      <div className="AdminDashboard-profile">
        <AdminProfile adminProfileShow={true} />
      </div>
      <div className="AdminDashboard-space">
        <div className="AdminDashboard-mobile-header">
          <AdminProfile
            adminProfileShow={adminProfileShow}
            setAdminProfileShow={setAdminProfileShow}
          />
          <AdminHeader setAdminProfileShow={setAdminProfileShow} />
        </div>
        <section className="AdminDashboard-space-board">
          <div className="AdminDashboard-board">
            <p>Administrative</p>
            <div className="AdminDashboard-board-btnHolder">
              <button>
                <Link to="/CreateStaff">Staff</Link>{" "}
              </button>
              <button>
                <Link to="/AdminAccounts">Account</Link>{" "}
              </button>
              <button>
                <Link to="/AdminManageGuest">Guest</Link>
              </button>
              <button>
                <Link to="/Expenses">Expenses</Link>
              </button>
            </div>
          </div>
          <div className="AdminDashboard-board">
            <p>Settings</p>
            <div className="AdminDashboard-board-btnHolder">
              <button>Prices</button>
              <button onClick={handleMessagingClick}>Messaging</button>
              <button>Categories</button>
              <button>Position</button>
              <button>Salary</button>
            </div>
          </div>
        </section>
      </div>
      
      {/* Modal for QR Code */}
      {showModal && (
        <div className="modal" onClick={handleCloseModal}>
          <div className="modal-content">
            <h2>Scan the QR Code</h2>
            
            {qrCode ? (
              <QRCodeCanvas value={qrCode} size={256} />
            ) : (
              <p>Loading QR Code...</p>
            )}
            
          
          </div>
        </div>
      )}
      
      
    </div>
  );
};

export default AdminDashboard;
